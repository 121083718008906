import React, { useState } from "react";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import SyncIcon from '@material-ui/icons/Sync';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Backdrop from "../Backdrop";
import SnackBar from "../SnackBar";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import "./dashboard.css";

import SalesPanel from "./Sales/sales";
import PurchasesPanel from "./Purchases/purchases";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import calls from "../../Js/calls";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
}))(Tooltip);

const CustomTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#4a22d4',
    },
})(Tabs);

const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: theme.spacing(1),
        fontSize: '16px',
        fontFamily: [
            'Goldplay',
            'sans-serif',
        ].join(','),
        '&:hover': {
            color: '#4a22d4',
            opacity: 1,
        },
        '&$selected': {
            color: '#4a22d4',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#4a22d4',
        },
    },
    selected: {},
}))(Tab);


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    paper: {
        borderRadius: '10px',
        boxShadow: '10px 10px 5px 0px rgba(74,34,212,0.1)'
    },
}));

const Dashboard = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [t] = useTranslation("global");
    const todos = useSelector(state => state.todos);
    // Manages loading showing
    const [loading, showLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [texto, setTexto] = useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const onClickUpdateOrgPanel = async () => {
        showLoading(true);
        await calls.updateDashboardPanel(todos.orgID)
            .then(() => {
                setShow(true);
                setTexto(t("principal.update"));
                setAlertType("info");
            });
        showLoading(false);
    }

    return (
        <Container
            maxWidth="lg"
            className="mainPanel"
        >
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs className="botBreadcrumb">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Typography variant="h1" color="textSecondary">{t("principal.dashboard")}</Typography>
                        <Typography variant="h1" color="textSecondary">{todos.ddlval}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item className="botBreadcrumb">
                    <LightTooltip title={t("principal.updateOrg")} aria-label="update" placement="left">
                        <IconButton size="small" onClick={() => onClickUpdateOrgPanel()}>
                            <SyncIcon id="updateIcon" color="primary" fontSize="large" />
                        </IconButton>
                    </LightTooltip>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <div className={classes.root}>
                            {/*Pintado de grid dependiendo del menu superior del grid*/}
                            <CustomTabs value={value} onChange={handleChange} aria-label="tabs" variant="scrollable" scrollButtons="auto">
                                <CustomTab label={t("principal.invoices")} {...a11yProps(0)} />
                                <CustomTab label={t("principal.bills")} {...a11yProps(1)} />
                            </CustomTabs>
                            <TabPanel value={value} index={0}>
                                <SalesPanel orgIdSelected={todos.orgID} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <PurchasesPanel orgIdSelected={todos.orgID} />
                            </TabPanel>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleCloseSnack}
            />
            <Backdrop
                open={loading}
            />
        </Container>
    );
}

export default Dashboard;