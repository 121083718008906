/** Components ----------------------------------------------------------- */
import { createTheme } from '@material-ui/core/styles';

/** Global variables ----------------------------------------------------------- */
import GoldplayRegular from './fonts/goldplay/Goldplay-Regular.otf';
import GoldplaySemiBold from './fonts/goldplay/Goldplay-SemiBold.otf';
import GoldplayBold from './fonts/goldplay/Goldplay-Bold.otf';

/* Font settings */
const goldplay = {
    fontFamily: 'Goldplay',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
        local('Goldplay'),
        local('Goldplay-Regular'),
        url(${GoldplayRegular}) format('opentype')
    `,
}

const goldplaySemiBold = {
    fontFamily: 'Goldplay',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
        local('Goldplay'),
        local('Goldplay-SemiBold'),
        url(${GoldplaySemiBold}) format('opentype')
    `,
}

const goldplayBold = {
    fontFamily: 'Goldplay',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
        local('Goldplay'),
        local('Goldplay-Bold'),
        url(${GoldplayBold}) format('opentype')
    `,
}

/* Theme settings */
const theme = createTheme({
    mixins: {
        toolbar: {
            minHeight: 50,
        }
    },
    palette: {
        primary: {
            main: '#4a22d4',
        },
        secondary: {
            main: '#00ffff',
        },
        text: {
            primary: '#131f3e',
            secondary: '#927ae5',
        },
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: 'Goldplay, sans-serif',
        fontWeightMedium: 600,
        h1: {
            fontFamily: 'Goldplay, sans-serif',
            fontWeight: 600,
            fontSize: 18,
        },
        h2: {
            fontFamily: 'Goldplay, sans-serif',
            fontWeight: 600,
            fontSize: 16,
        },
        h3: {
            fontFamily: 'Goldplay, sans-serif',
            fontWeight: 600,
            fontSize: 15,
        },
        h4: {
            fontFamily: 'Goldplay, sans-serif',
            fontWeight: 600,
            fontSize: 14,
        },
        subtitle1: {
            fontFamily: 'Goldplay, sans-serif',
            fontWeight: 400,
            fontSize: 30,
        },
        subtitle2: {
            fontFamily: 'Goldplay, sans-serif',
            fontWeight: 600,
            fontSize: 25,
        },
        body1: {
            fontFamily: 'Goldplay, sans-serif',
            fontWeight: 400,
            fontSize: 14,
        },
        body2: {
            fontFamily: 'Goldplay, sans-serif',
            fontWeight: 400,
            fontSize: 15,
        },
        button: {
            fontFamily: 'Goldplay, sans-serif',
            fontWeight: 600,
            fontSize: 15,
            textTransform: 'none',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [goldplay, goldplaySemiBold, goldplayBold],
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#4a22d4",
                '& button:hover': {
                    backgroundColor: "#927ae5"
                },
            }
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                '& span': {
                    color: "#4a22d4",
                },
                '& p': {
                    fontWeight: "600",
                    color: "#4a22d4",
                    fontSize: 14,
                },
            },
            daysHeader: {
                '& span': {
                    fontWeight: "600",
                    color: "#927ae5",
                    fontSize: 10,
                },
            },
        },
        MuiPickersDay: {
            day: {
                color: "#131f3e",
                '& p': {
                    fontWeight: "600",
                    fontSize: 14,
                },
            },
            daySelected: {
                color: "#131f3e",
                backgroundColor: "#e4def9",
                '&:hover': {
                    backgroundColor: "#e4def9"
                },
            },
            current: {
                color: "#4a22d4",
            },
        },
    }
});

export default theme;