/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

/** Icons */
import { ReactComponent as AvatarIcon } from '../Imagenes/user-avatar-icon.svg';
import { ReactComponent as SettingsIcon } from '../Imagenes/settings-icon.svg';
import { ReactComponent as KiiperAccounting } from '../Imagenes/kiiper-taxes-logo.svg';
import { ReactComponent as KiiperAccountingMobile } from '../Imagenes/kiiper-taxes-mobile-logo.svg';
import { ReactComponent as ArrowDownIcon } from '../Imagenes/arrow-down-icon.svg';
import { ReactComponent as ChevronLeftIcon } from '../Imagenes/chevron-left-icon.svg';
import { ReactComponent as DisconnectButton } from '../Imagenes/disconnect-white.svg';
import { ReactComponent as TranslateIcon } from '../Imagenes/translate-icon.svg';

/** Components ----------------------------------------------------------- */
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from '@material-ui/core/Button';
import Container from "@material-ui/core/Container";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import Hidden from '@material-ui/core/Hidden';
import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Backdrop from "./internos/Backdrop";
import Paper from "@material-ui/core/Paper";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import Collapse from '@material-ui/core/Collapse';
import calls from "./Js/calls";
import util from "./Js/util";

/** Global variables ----------------------------------------------------------- */
const drawerWidth = 240;
var myGroup = [];

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        backgroundColor: '#131f3e',
    },
    kiiperNavbarLogo: {
        display: 'block',
        height: 50,
        [theme.breakpoints.down('xs')]: {
            width: '140px'
        },
    },
    languageBtn: {
        padding: '6px 20px',
    },
    avatarIcon: {
        [theme.breakpoints.down('sm')]: {
            height: 20,
        },
        [theme.breakpoints.up('md')]: {
            height: 25,
        },
        fill: '#00ffff',
    },
    avatarImage: {
        width: '60px',
        fill: '#4a22d4 !important'
    },
    settingsBtn: {
        borderBottom: '1px solid #E7E7EB',
        borderRadius: '0',
        '&:hover': {
            backgroundColor: '#faf8fe',
        }
    },
    gobackBtn: {
        background: 'none !important',
        padding: '0px'
    },
    gobackIcon: {
        height: 40,
        margin: 'auto !important',
        '&:hover': {
            backgroundColor: '#e4def9',
            borderRadius: 6,
        }
    },
    toolbarIcon: {
        display: "inline-block",
        [theme.breakpoints.up('xs')]: {
            paddingLeft: "15px",
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "40px",
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: "40px",
        },
    },
    toolbarSelect: {
        display: "inline-block",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0px",
        },
    },
    navBarTwo: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        height: '90px',
        padding: '0',
        backgroundColor: '#FFFFFF !important',
    },
    formControlGrp: {
        backgroundColor: '#FFFFFF !important',
        width: 240,
        borderRadius: 10,
        verticalAlign: 'middle !important',
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #4a22d4',
            borderRadius: 10,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #4a22d4',
            borderRadius: 10,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #4a22d4',
            borderRadius: 10,
        },
    },
    formControlOrg: {
        backgroundColor: '#e6defd !important',
        [theme.breakpoints.up('xs')]: {
            width: 240,
        },
        [theme.breakpoints.down('xs')]: {
            width: 200,
        },
        borderRadius: 10,
        verticalAlign: 'middle !important',
        "&:focus-within": {
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #4a22d4',
            borderRadius: 10,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #4a22d4',
            borderRadius: 10,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #4a22d4',
            borderRadius: 10,
        },
    },
    selectRoot: {
        backgroundColor: 'transparent !important',
    },
    selectPaper: {
        marginTop: 10,
        borderRadius: 10,
        boxShadow: '10px 10px 15px 0px rgba(74,34,212,0.3)',
        '& li': {
            background: 'none !important',
        },
        '& li:hover': {
            backgroundColor: '#faf8fe !important',
        }
    },
    selecText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    nested: {
        '& .MuiTypography-root': {
            color: '#131f3e !important',
            fontWeight: '600 !important',
            fontSize: '14px !important',
            '&:hover': {
                color: '#5540c2 !important'
            },
        },
        paddingLeft: theme.spacing(4),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
}));
//#endregion

const iconStyles = theme => ({
    selectIcon: {
        [theme.breakpoints.up('xl')]: {
            height: '25px',
        },
        [theme.breakpoints.down('lg')]: {
            height: '25px',
        },
    }
});

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ArrowDownIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const StyledMenu = withStyles({
    paper: {
        width: 120,
        boxShadow: '10px 10px 15px 0px rgba(74,34,212,0.3)',
    },
    list: {
        '& li:hover': {
            backgroundColor: '#faf8fe',
        }
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

//Función utilizada obtener todas las prácticas
const getAllPractices = async () => {
    return await calls.getAllPractices();
}

//Función utilizada obtener todas las organizaciones asociadas a las prácticas
const returnOrganizations = async (item) => {
    //Retrive data from organizarion and use for fill ddl
    return await calls.getOrganizations(item);
}

//Función utilizada para obtener el impuesto de cada organización
const returnTaxStatus = async (item) => {
    return await calls.returnTaxStatus(item);
}

const NavBar = ({ history }) => {
    const classes = useStyles();
    const theme = useTheme();
    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();

    const todos = useSelector(state => state.todos);

    const fillDropDownListGroup = () => {
        var groups = todos.grp.map((res) => {
            return {
                type: "xeroGroupName",
                name: res.practiceName,
                id: res.practiceID,
            };
        });

        return util.distinct(groups, "name");
    }

    const group = fillDropDownListGroup();

    /** Component states */
    const [avatarOptionsFlag, showAvatarOptions] = useState(false);
    const [showBackdrop, setBackdrop] = useState(false);
    const [language, setLanguage] = useState(sessionStorage.getItem('lng') === 'es' ? 'Español' : 'English');
    const [languageMobile, setlanguageMobile] = useState(sessionStorage.getItem('lng') === 'es' ? 'ES' : 'EN');
    const [anchorEl, setAnchorEl] = useState(null);

    const [textMainP, settextMainP] = useState("");
    const [textNegocio, settextNegocio] = useState("");
    const [textVentas, settextVentas] = useState("");
    const [textCompras, settextCompras] = useState("");
    const [textReportes, settextReportes] = useState("");
    const [textFiscales, settextFiscales] = useState("");
    const [textLegales, settextLegales] = useState("");

    const [mobileOpen, setMobileOpen] = useState(false);
    const [opendraw, setOpendraw] = useState(false);
    const [opendraw2, setOpendraw2] = useState(false);
    const [opendraw3, setOpendraw3] = useState(false);
    const [open, setOpen] = useState(false);
    const [open3, setOpen3] = useState(false);
    const anchorRef = useRef(null);
    const anchorRef2 = useRef(null);
    const anchorRef3 = useRef(null);
    const location = useLocation();

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
            setOpen3(false);
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClickDrawer = (valor) => {
        switch (valor) {
            case 1:
                setOpendraw2(false);
                setOpendraw3(false);
                setOpendraw(!opendraw);
                break;
            case 2:
                setOpendraw(false);
                setOpendraw3(false);
                setOpendraw2(!opendraw2);
                break;
            case 3:
                setOpendraw(false);
                setOpendraw2(false);
                setOpendraw3(!opendraw3);
                break;
            default:
                break;
        }
    };

    const handleToggle = (valor) => {
        switch (valor) {
            case 1:
                setOpen3(false);
                setOpen((prevOpen) => !prevOpen);
                break;
            case 2:
                setOpen(false);
                setOpen3(false);
                break;
            case 3:
                setOpen(false);
                setOpen3((prevOpen3) => !prevOpen3);
                break;
            default:
                break;
        }
    };

    const checkSession = async () => {
        await fetch("/checkSession")
            .then((res) => res.json())
            .then((data) => {
                if (!data) {
                    dispatch({
                        type: 'clearInfo'
                    })
                    history.push("/");
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        setInterval(checkSession, 60000);

        if (location.pathname === "/home") {
            dispatch({
                type: 'addEvents',
                item: -2
            })
        }

        settextMainP(t("principal.dashboard"));
        settextNegocio(t("principal.business"));
        settextVentas(t("principal.invoices"));
        settextCompras(t("principal.bills"));
        settextReportes(t("principal.reports"));
        settextFiscales(t("principal.prosecutors"));
        settextLegales(t("principal.legal"));

        return history.listen(location => {
            if (history.action === 'POP') {
                if (location.pathname === "/home") {
                    dispatch({
                        type: 'addEvents',
                        item: -2
                    })
                }
                if (location.pathname === "/dashboard") {
                    dispatch({
                        type: 'addEvents',
                        item: 'xeroOrgName'
                    })
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const handleClickLng = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
            return;
        }
        if (anchorRef3.current && anchorRef3.current.contains(event.target)) {
            return;
        }

        setOpen(false);
        setOpen3(false);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };

    const updateLanguage = (lng) => {
        if (lng === 'English') {
            setLanguage(lng);
            setlanguageMobile("EN");
            sessionStorage.setItem('lng', 'en');
            i18n.changeLanguage("en");
        } else {
            setLanguage(lng);
            setlanguageMobile("ES");
            sessionStorage.setItem('lng', 'es');
            i18n.changeLanguage("es");
        }
    }

    /** Functions */
    const handleClickAway = () => {
        showAvatarOptions(false);
    };

    /** Resets banks module */
    const handleCancel = () => {
        setBackdrop(true);
        calls.logoutFunction().then(() => {
            dispatch({
                type: 'clearInfo'
            })
            history.push("/");
        });
    };

    const onAdminPanelClick = async () => {
        dispatch({
            type: 'addSelected',
            item: ''
        })
        dispatch({
            type: 'addEvents',
            item: 'configPanel'
        });
        await returnTaxStatus(todos.orgID).then((result) => {
            dispatch({
                type: 'addTaxes',
                item: result.data
            });
        });
        if (location.pathname === "/settings") {
            history.push("/");
        }
        history.push("/settings");
    };

    let handleClick = async (event, value) => {
        if (value !== -1) {
            let item = value.props.value;

            // Setting group selected
            if (item.type === "xeroGroupName") {
                dispatch({
                    type: 'addPracticeName',
                    item: item.name
                })
                dispatch({
                    type: 'addPracticeID',
                    item: item.id
                })
                myGroup = [];
                myGroup = await getAllPractices();
                var grupo = {};
                grupo.id = myGroup.length + 1;
                grupo.name = "_______________________________________";
                grupo.type = "xeroGroupName";
                myGroup.push(grupo);

                var grupo2 = {};
                grupo2.id = myGroup.length + 1;
                grupo2.name = "Agregar grupo";
                grupo2.type = "xeroGroupName";
                myGroup.push(grupo2);
            }

            // Setting organisation selected
            if (item.type === "xeroOrgName") {
                dispatch({
                    type: 'addOrgID',
                    item: item.id
                })
            }

            if (typeof item.type === "string") {
                if (item.type === "xeroGroupName") {
                    let organizations = await returnOrganizations(item.id);
                    organizations = organizations.concat(
                        group.filter((g) => g.name !== item.name)
                    );
                    dispatch({
                        type: 'addEvents',
                        item: -2
                    })
                    dispatch({
                        type: 'addOrganisations',
                        item: organizations
                    })
                    history.push("/home");
                } else {
                    dispatch({
                        type: 'addEvents',
                        item: "xeroOrgName"
                    })
                    dispatch({
                        type: 'addSelected',
                        item: 'ddlPrincipal'
                    })
                    history.push("/dashboard");
                }

                // Setting organization selected in React to component
                dispatch({
                    type: 'addOrgName',
                    item: item.type === "xeroGroupName" ? "" : item.name
                })
                dispatch({
                    type: 'addDropdownValue',
                    item: item.name
                })
                dispatch({
                    type: 'addOrgID',
                    item: item.id
                })
            }
        }
        else {
            let organizations = await returnOrganizations(todos.practiceID);
            organizations = organizations.concat(
                group.filter((g) => g.name !== todos.practiceName)
            );
            dispatch({
                type: 'addOrganisations',
                item: organizations
            })
            dispatch({
                type: 'addEvents',
                item: -2
            })
            dispatch({
                type: 'addDropdownValue',
                item: todos.practiceName
            })
            dispatch({
                type: 'addOrgName',
                item: ''
            })
        }
    };

    let valueInput = (item) => (
        <Typography variant="h4" component="span" align="center" color="textPrimary">
            {item.name}
        </Typography>
    );

    // When selecting menu option
    const selectMenuOption = (event, index) => {
        switch (index) {
            case 0.1:
                dispatch({
                    type: 'addSelected',
                    item: 'ddlPrincipal'
                })
                history.push("/dashboard");
                break;
            case 1.1:
                dispatch({
                    type: 'addSelected',
                    item: 'ddlNegocioId'
                })
                history.push("/business/invoices");
                break;
            case 1.2:
                dispatch({
                    type: 'addSelected',
                    item: 'ddlNegocioId'
                })
                history.push("/business/bills");
                break;
            case 3.1:
                dispatch({
                    type: 'addSelected',
                    item: 'ddlReportesId'
                })
                history.push("/reports/fiscal");
                break;
            case 3.2:
                dispatch({
                    type: 'addSelected',
                    item: 'ddlReportesId'
                })
                history.push("/reports/legal");
                break;
            default:
                break;
        }
    };

    const handleListItemClick = async (event, index) => {
        selectMenuOption(event, index);
    };

    const drawer = (
        <>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItem button component="a"
                    id="ddlPrincipalDraw"
                    className={todos.selected === "ddlPrincipal" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"}
                    eventKey={0.1}
                    onClick={(event) => handleListItemClick(event, 0.1)}>
                    <ListItemText primary={textMainP} />
                </ListItem>
                <ListItem button
                    id="ddlNegocioIdDraw"
                    className={todos.selected === "ddlNegocioId" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"}
                    onClick={() => handleClickDrawer(1)}>
                    <ListItemText primary={textNegocio} />
                    {opendraw ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={opendraw} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button
                            eventKey={1.1} component="a"
                            onClick={(event) => { handleListItemClick(event, 1.1) }}
                            className={classes.nested}>
                            <ListItemText primary={textVentas} />
                        </ListItem>
                        <ListItem button
                            eventKey={1.2} component="a"
                            onClick={(event) => { handleListItemClick(event, 1.2) }}
                            className={classes.nested}>
                            <ListItemText primary={textCompras} />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem button
                    id="ddlReportesIdDraw"
                    className={todos.selected === "ddlReportesId" ? "navBarOptionSelected submenuKiiper" : "submenuKiiper"}
                    onClick={() => handleClickDrawer(3)}>
                    <ListItemText primary={textReportes} />
                    {opendraw3 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={opendraw3} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button eventKey={3.1} component="a"
                            onClick={(event) => { handleListItemClick(event, 3.1) }}
                            className={classes.nested}>
                            <ListItemText primary={textFiscales} />
                        </ListItem>
                        <ListItem button eventKey={3.2} component="a"
                            onClick={(event) => { handleListItemClick(event, 3.2) }}
                            className={classes.nested}>
                            <ListItemText primary={textLegales} />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </>
    );

    /** Select options to show ----------------------------------------------------------- */
    let mainSelect = '';

    if (todos.event === -2 || todos.event === "xeroOrgName" || todos.event === "configPanel") {
        mainSelect = [
            <FormControl variant="outlined" size="small"
                classes={todos.event === -2 ? { root: classes.formControlGrp } : { root: classes.formControlOrg }}
            >
                <Select
                    classes={{ root: classes.selectRoot }}
                    value={todos.ddlval}
                    onChange={handleClick}
                    fullWidth
                    displayEmpty
                    renderValue={(value) =>
                        <Typography variant="h2" align="left" color="primary" className={classes.selecText}> {value} </Typography>
                    }
                    IconComponent={CustomExpandMore}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        classes: {
                            paper: classes.selectPaper
                        }
                    }}
                >
                    {todos.organisations.map(xero =>
                        <MenuItem key={xero.id} value={xero}> {valueInput(xero)} </MenuItem>
                    )}
                </Select>
            </FormControl>
        ];
    } else {
        mainSelect = [
            <FormControl variant="outlined" size="small"
                classes={todos.event === -2 ? { root: classes.formControlGrp } : { root: classes.formControlOrg }}
            >
                <Select
                    classes={{ root: classes.selectRoot }}
                    value={todos.ddlval}
                    onChange={handleClick}
                    fullWidth
                    displayEmpty
                    renderValue={(value) =>
                        <Typography variant="h2" align="left" color="primary" className={classes.selecText}> {value} </Typography>
                    }
                    IconComponent={CustomExpandMore}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        classes: {
                            paper: classes.selectPaper
                        }
                    }}
                >
                    {group.map(xero =>
                        <MenuItem key={xero.id} value={xero}> {valueInput(xero)} </MenuItem>
                    )}
                </Select>
            </FormControl>
        ];
    }

    return (
        <div className={classes.root}>
            <AppBar>
                <Toolbar className={classes.toolbar}>
                    <Hidden only={['sm', 'xs']}>
                        <KiiperAccounting className={classes.kiiperNavbarLogo} />
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <KiiperAccountingMobile className={classes.kiiperNavbarLogo} />
                    </Hidden>
                    <div>
                        <LightTooltip title={t("miscellaneous.changeLng")} aria-label="changeLng">
                            <Button aria-controls="language-menu" aria-haspopup="true" onClick={handleClickLng}
                                className={classes.languageBtn}
                            >
                                <TranslateIcon className="icon-text-size" />
                                <Hidden only={['xs']}>
                                    <Typography variant="button" align="center" color="secondary">
                                        {language}
                                    </Typography>
                                </Hidden>
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <Typography variant="button" align="center" color="secondary">
                                        {languageMobile}
                                    </Typography>
                                </Hidden>
                            </Button>
                        </LightTooltip>
                        <StyledMenu
                            id="language-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseAnchor}
                        >
                            <MenuItem onClick={() => { handleCloseAnchor(); updateLanguage("English") }}>
                                <Typography variant="body1" align="center" color="textPrimary" >
                                    English
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => { handleCloseAnchor(); updateLanguage("Español") }}>
                                <Typography variant="body1" align="center" color="textPrimary" >
                                    Español
                                </Typography>
                            </MenuItem>
                        </StyledMenu >
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    avatarOptionsFlag
                                        ? showAvatarOptions(false)
                                        : showAvatarOptions(true);
                                }}
                            >
                                <AvatarIcon className={classes.avatarIcon} />
                                {avatarOptionsFlag ? (
                                    <div className="avatarOptions">
                                        <div className="avatarInfo">
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={4}>
                                                    <AvatarIcon className={classes.avatarImage} />
                                                </Grid>
                                                <Grid item xs={12} md={8}>
                                                    <div className="avatarInfoText avatarInfoName">
                                                        <Typography variant="h4" align="center" color="textPrimary" >
                                                            {todos.userInfo.name}
                                                        </Typography>
                                                    </div>
                                                    <div className="avatarInfoText">
                                                        <Typography variant="body1" align="center" color="textPrimary" >
                                                            {todos.userInfo.email}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        {todos.orgName !== '' ? (
                                            <Button
                                                onClick={() => onAdminPanelClick()}
                                                fullWidth
                                                className={classes.settingsBtn}
                                            >
                                                <SettingsIcon className="icon-text-size" />
                                                <Typography variant="button" align="center" color="primary" >
                                                    {t("principal.settings")}
                                                </Typography>
                                            </Button>
                                        ) : null}
                                        <LightTooltip title={t("principal.logout")}>
                                            <Button style={{ width: '70%' }}>
                                                <DisconnectButton onClick={() => handleCancel()} />
                                            </Button>
                                        </LightTooltip>
                                    </div>
                                ) : null}
                            </IconButton>
                        </ClickAwayListener>
                    </div>
                </Toolbar>
            </AppBar>
            <div className={classes.offset} />
            <Container maxWidth="lg" className={classes.navBarTwo}>
                <Grid container alignItems="center">
                    <Grid item>
                        {/*Menú principal principal */}
                        {todos.event === "xeroOrgName" || todos.event === "configPanel" ? (
                            <div className={classes.toolbarIcon}>
                                <Link to="/home">
                                    <IconButton
                                        onClick={(event) => {
                                            handleClick(event, -1);
                                            history.push("/home");
                                        }}
                                        className={classes.gobackBtn}
                                    >
                                        <ChevronLeftIcon className={classes.gobackIcon} />
                                    </IconButton>
                                </Link>
                            </div>
                        ) : null}
                        <div className={classes.toolbarIcon}>
                            {mainSelect}
                        </div>
                    </Grid>
                    {todos.event === "xeroOrgName" || todos.event === "configPanel" ? (
                        <Grid item xs={1} md={7}>
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Hidden mdUp implementation="css">
                                <Drawer
                                    variant="temporary"
                                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                            </Hidden>
                            <Hidden mdDown implementation="css">
                                <List className="navMenuKiiper">
                                    <ListItem button component="a" className={todos.selected === "ddlPrincipal" ? "navBarOptionSelected" : ""}
                                        id="ddlPrincipal"
                                        eventKey={0.1}
                                        onClick={(event) => handleListItemClick(event, 0.1)}>
                                        <ListItemText primary={t("principal.dashboard")} />
                                    </ListItem>
                                    <ListItem button component="a" className={todos.selected === "ddlNegocioId" ? "navBarOptionSelected" : ""}
                                        id="ddlNegocioId"
                                        ref={anchorRef}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={() => handleToggle(1)}>
                                        <ListItemText primary={t("principal.business")} />
                                        {open ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', width: '160px' }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                            <MenuItem id="ddlNegocioVentas" className="submenuKiiper"
                                                                eventKey={1.1} component="a"
                                                                onClick={(event) => { handleListItemClick(event, 1.1); handleClose(event) }}
                                                            >{t("principal.invoices")}</MenuItem>
                                                            <MenuItem id="ddlNegocioCompras" className="submenuKiiper"
                                                                eventKey={1.2} component="a"
                                                                onClick={(event) => { handleListItemClick(event, 1.2); handleClose(event) }}
                                                            >{t("principal.bills")}</MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                    <ListItem button component="a" className={todos.selected === "ddlReportesId" ? "navBarOptionSelected" : ""}
                                        id="ddlReportesId"
                                        ref={anchorRef3}
                                        aria-controls={open3 ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={() => handleToggle(3)}>
                                        <ListItemText primary={t("principal.reports")} />
                                        {open3 ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Popper open={open3} anchorEl={anchorRef3.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', width: '160px' }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList autoFocusItem={open3} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                            <MenuItem className="submenuKiiper"
                                                                eventKey={3.1} component="a"
                                                                onClick={(event) => { handleListItemClick(event, 3.1); handleClose(event) }}
                                                            >{t("principal.prosecutors")}</MenuItem>
                                                            <MenuItem className="submenuKiiper"
                                                                eventKey={3.2} component="a"
                                                                onClick={(event) => { handleListItemClick(event, 3.2); handleClose(event) }}
                                                            >{t("principal.legal")}</MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </List>
                            </Hidden>
                            <Toolbar position="fixed">
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Toolbar>
                        </Grid>
                    ) : null}
                </Grid>
            </Container>
            <Backdrop
                open={showBackdrop}
            />
        </div>
    )
}

export default withRouter(NavBar);