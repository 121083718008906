/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';

/** Components ----------------------------------------------------------- */
import Backdrop from "./internos/Backdrop";
import calls from "./Js/calls";
import util from "./Js/util";
import AlertDialog from "./internos/Dialog";
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

const Loading = () => {
    /* React redux */
    const dispatch = useDispatch();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const [isInActive, setIsInActive] = useState(true);

    const [isRedirect, setRedirect] = useState(false);

    const [showBackdrop, setBackdrop] = useState(false);

    const [showDialog, setshowDialog] = useState(false);

    useEffect(() => {
        const fetchdata = async () => {
            setBackdrop(true);
            await fetch("/checkSession")
                .then((res) => res.json())
                .then((data) => {
                    if (!data) {
                        dispatch({
                            type: 'clearInfo'
                        })
                        setRedirect(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
            let grupo = await calls.getGroupsList();
            if (grupo !== undefined) {
                if (grupo.length === 0) {
                    setshowDialog(true);
                }
                else {
                    let userInfo = await calls.getUserInfo();

                    dispatch({
                        type: 'addAuth',
                        item: true
                    })

                    dispatch({
                        type: 'addUserInfo',
                        item: userInfo
                    })

                    let groups = grupo.map((res) => {
                        return {
                            type: "xeroGroupName",
                            name: res.practiceName,
                            id: res.practiceID,
                        };
                    });

                    groups = util.distinct(groups, "name");

                    dispatch({
                        type: 'addOrgName',
                        item: ''
                    })

                    dispatch({
                        type: 'addGroup',
                        item: grupo
                    })

                    dispatch({
                        type: 'addPracticeID',
                        item: groups[0].id
                    })

                    dispatch({
                        type: 'addPracticeName',
                        item: groups[0].name
                    })

                    dispatch({
                        type: 'addDropdownValue',
                        item: groups[0].name
                    })

                    let organizations = await returnOrganizations(groups[0].id);

                    organizations = organizations.concat(
                        groups.filter((g) => g.name !== groups[0].name)
                    );

                    dispatch({
                        type: 'addOrganisations',
                        item: organizations
                    })

                    dispatch({
                        type: 'addEvents',
                        item: -2
                    })

                    setIsInActive(false);
                }
            }
            setBackdrop(false);
        }
        fetchdata();
    }, [dispatch]);

    // Función utilizada obtener todas las organizaciones asociadas a las prácticas
    const returnOrganizations = async (item) => {
        //Retrive data from organizarion and use for fill ddl
        return await calls.getOrganizations(item);
    }
    
    const logout = async () => {
        setBackdrop(true);
        await calls.logoutFunction().then(() => setRedirect(true));
    }

    const boton = (
        <Button onClick={() => {
            setshowDialog(false);
            logout();
        }}
            color="primary" variant="contained" className="btnRoot" disableElevation>
            Aceptar
        </Button>
    )

    return (
        <>
            <AlertDialog
                open={showDialog}
                onClose={() => {
                    setshowDialog(false);
                    logout();
                }}
                message={t("miscellaneous.noAccess")}
                title={t("miscellaneous.attention")}
                button={boton}
                noBackDrop={true}
            />
            <Backdrop
                open={showBackdrop}
            />
            {/*  Acción utilizada ocultar el logo de kiiper para darle paso al formulario de inicio de sesion */}
            {!isInActive ?
                <Redirect to='/home' />
                : isRedirect ? <Redirect to='/' /> : null}
        </>
    )
}

export default Loading;