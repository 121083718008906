import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

/// controles
import AlertDialog from "../Dialog";
import { PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import GridUI from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import GetAppIcon from '@material-ui/icons/GetApp';
import UpdateIcon from '@material-ui/icons/Update';
import Tooltip from '@material-ui/core/Tooltip';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

/// Componentes
import calls from "../../Js/calls";
import util from "../../Js/util";
import Backdrop from "../Backdrop";
import SnackBar from "../SnackBar";
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { ReactComponent as ArrowDownIcon } from '../../../Imagenes/arrow-down-icon.svg';

/// CSS
import "../Css/books.css";
import "../Css/Reports/reports.css"
import clsx from "clsx";

var group = [];

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
}))(Tooltip);

const tableStyles = {
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: '#faf8fe',
        },
    },
    tableHeader: {
        border: 'none',
        backgroundColor: '#e4def9',
        fontSize: '15px',
        fontWeight: 600,
        color: '#131f3e',
        '&:first-child': {
            borderRadius: '10px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 10px 0 0',
        }
    },
    tableBody: {
        border: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#131f3e',
    },
};

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#f5f6fa',
    },
})(MuiAccordionSummary);

const tableHeaderCellBase = ({ classes, ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableComponentBase = ({ classes, ...props }) => (
    <Table.Table
        {...props}
        className={classes.tableStriped}
    />
);

const TableCellComponentBase = ({ classes, ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const tableContentComponent = props => (
    <TableHeaderRow.Content {...props} align={'center'} />
);

const tableHeaderCell = withStyles(tableStyles, { name: 'tableHeaderCell' })(tableHeaderCellBase);
const TableComponent = withStyles(tableStyles, { name: 'TableComponent' })(TableComponentBase);
const TableCellComponent = withStyles(tableStyles, { name: 'TableCellComponent' })(TableCellComponentBase);

const useStyles = makeStyles(() => ({
    paper: {
        borderRadius: '10px',
        boxShadow: '10px 10px 5px 0px rgba(74,34,212,0.1)',
        fontSize: '14px'
    },
}));

const iconStyles = theme => ({
    selectIcon: {
        [theme.breakpoints.up('xl')]: {
            height: '25px',
        },
        [theme.breakpoints.down('lg')]: {
            height: '25px',
        },
    }
});

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ArrowDownIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const Reports = () => {
    const todos = useSelector(state => state.todos);
    const [taxes, setTaxes] = useState(true);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setalertType] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation("global");
    const classes = useStyles();

    const reports = [
        { id: "fiscalBook", book: t("reports.fiscalBook") },
        { id: "retIVA", book: t("taxes.retIVA") },
        { id: "retISLR", book: t("taxes.retISLR") }
    ];

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    }

    useEffect(() => {
        returnTaxStatus(todos.orgID).then(async (result) => {
            setTexto(t("miscellaneous.notActMod"));
            setalertType("info");
            setShow(result.data === true ? false : true);
            setTaxes(result.data);
        });

        returnProvee(todos.orgID).then(result => {
            group = result.data;
        });
    }, [todos.orgID, t]);

    //Función utilizada para obtener el proveedor
    const returnProvee = async (item) => {
        return await calls.returnProveeImp(item);
    }

    //Función utilizada para obtener el impuesto de cada organización
    const returnTaxStatus = async (item) => {
        return await calls.returnTaxStatus(item);
    }

    return (
        <Container maxWidth="lg" className="mainPanel">
            <GridUI id={taxes === true ? "" : "overlayLF"} container spacing={2} justifyContent="center" alignItems="center">
                <GridUI item xs className="botBreadcrumb">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Typography variant="h1" color="textSecondary">{t("principal.reports")}</Typography>
                        <Typography variant="h1" color="textSecondary">{t("principal.prosecutors")}</Typography>
                    </Breadcrumbs>
                </GridUI>
                <GridUI item xs={12}>
                    <Paper className={classes.paper}>
                        {reports.map((report, index) => (
                            report.id === "fiscalBook" ?
                                <ReportBook
                                    key={`report${index}`}
                                    reportId={report.id}
                                    bookName={report.book}
                                    orgIdSelected={todos.orgID}
                                    expanded={expanded}
                                    handleChange={handleChange}
                                    setLoading={setLoading}
                                />
                                :
                                <ReportBook2
                                    key={`report${index}`}
                                    reportId={report.id}
                                    bookName={report.book}
                                    orgIdSelected={todos.orgID}
                                    expanded={expanded}
                                    handleChange={handleChange}
                                    setLoading={setLoading}
                                />
                        ))}
                    </Paper>
                </GridUI>
            </GridUI>
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleClose}
            />
            <Backdrop open={loading} />
        </Container>
    );
}

const ReportBook2 = (props) => {
    return (
        <Accordion expanded={props.expanded === props.reportId} onChange={props.handleChange(props.reportId)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className="acoordionOptionArrow" />}
                aria-controls="panel1bh-content"
                id={props.reportId}
                className="report-title"
            >
                <Typography variant="h3">{props.bookName}</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionBack">
                <GridUI
                    container
                    direction="row"
                >
                    <GridUI item xs={12}>
                        <Card className="card-container">
                            <CardContent>
                                <BookTable
                                    orgIdSelected={props.orgIdSelected}
                                    bookType={props.reportId}
                                    bookName={props.bookName}
                                    setLoading={props.setLoading}
                                />
                            </CardContent>
                        </Card>
                    </GridUI>
                </GridUI>
            </AccordionDetails>
        </Accordion>
    );
}

const ReportBook = (props) => {
    const [t] = useTranslation("global");
    return (
        <Accordion expanded={props.expanded === props.reportId} onChange={props.handleChange(props.reportId)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className="acoordionOptionArrow" />}
                aria-controls="panel1bh-content"
                id={props.reportId}
                className="report-title"
            >
                <Typography variant="h3">{props.bookName}</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionBack">
                <GridUI
                    container
                    direction="row"
                    spacing={2}
                >
                    <GridUI item xs={12} md={6}>
                        <Card className="card-container">
                            <CardHeader
                                title={<Typography variant="h2">{t("reports.billBook")}</Typography>}
                                className="cardHeaderRoot"
                            />
                            <CardContent className="cardContentRoot">
                                <BookTable
                                    orgIdSelected={props.orgIdSelected}
                                    bookType={"Compras"}
                                    setLoading={props.setLoading}
                                />
                            </CardContent>
                        </Card>
                    </GridUI>
                    <GridUI item xs={12} md={6}>
                        <Card className="card-container">
                            <CardHeader
                                title={<Typography variant="h2">{t("reports.invoiceBook")}</Typography>}
                                className="cardHeaderRoot"
                            />
                            <CardContent className="cardContentRoot">
                                <BookTable
                                    orgIdSelected={props.orgIdSelected}
                                    bookType={"Ventas"}
                                    setLoading={props.setLoading}
                                />
                            </CardContent>
                        </Card>
                    </GridUI>
                </GridUI>
            </AccordionDetails>
        </Accordion>
    );
}

const BookTable = (props) => {
    const [rowData, setrowData] = useState([]);
    const [columnDefs, setcolumnDefs] = useState([]);
    const [modalShow, setmodalShow] = useState(false);
    // const [taxbookId, settaxbookId] = useState("");
    const [startDate, setstartDate] = useState(new Date());
    const [finishDate, setfinishDate] = useState(new Date());
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setalertType] = useState("");
    const [isActive, setisActive] = useState(false);
    const [Value, setValue] = useState("");
    const [t] = useTranslation("global");
    moment.locale(t("manageAccount.dateFormat"));

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ''} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const tableColumnExtensions = [
        { columnName: 'end_date', align: 'center' },
        { columnName: 'init_date', align: 'center' },
        { columnName: 'download_rep', align: 'center' },
        { columnName: 'update_rep', align: 'center' }
    ];

    const handleClose2 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const handleClick = (event) => {
        setValue(event.target.value);
    };

    //#region Métodos de ciclo de vida
    useEffect(() => {
        const columnDefs = [
            { name: "init_date", title: t("reports.initDate") },
            { name: "end_date", title: t("reports.endDate") },
            { name: "download_rep", title: t("reports.file") },
            { name: "update_rep", title: t("taxes.updateF") },
        ];
        setcolumnDefs(columnDefs);
        onFillGrid("", "", "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const getAndBuildGridDataReports = (orgIdSelected, call, startDate, endDate) => {
        if (call === "") {
            var d = new Date();
            endDate = moment(d).format("DD/MM/YYYY")
            //startDate = "01/01/" + endDate.substring(6, 10);
            startDate = "01/01/2020";
        }

        return (
            // Getting specific organization data
            calls.getFiscalBooks(
                orgIdSelected,
                startDate,
                endDate
            ).then(result => {
                return result.data
            })
        );
    }

    /// Ayuda a determinar la manera default en que se presenta la columna Archivo
    const fileColumnRenderer = async (booktype, ID) => {
        props.setLoading(true);

        // Accion al dar click
        let endpoint = booktype === "Ventas" ? "/generateSalesBook" : "/generatePurchasesBook"
        let resp = endpoint + "?taxbookId=" + ID;

        if (resp !== false) {
            var element = document.createElement('a');
            element.setAttribute('href', resp);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        else {
            setTexto(t("manageAccount.dnldError"));
            setalertType("warning");
            setShow(true);
        }
        props.setLoading(false);
    }

    /// Ayuda a determinar la manera default en que se presenta la columna Archivo
    const updateFileColumnRenderer = async (booktype, ID) => {
        props.setLoading(true);

        if (booktype === "Compras") {
            await calls.getFiscalBooksCompras(ID).then(result => {
                setTexto(t("reports.billSuccess"));
                setalertType("success");
                setShow(true);
            }).catch((error) => {
                setTexto(t("reports.billError"));
                setalertType("error");
                setShow(true);
            });
        }
        else {
            await calls.getFiscalBooksVentas(ID).then(result => {
                setTexto(t("reports.invoiceSuccess"));
                setalertType("success");
                setShow(true);
            }).catch((error) => {
                setTexto(t("reports.invoiceError"));
                setalertType("error");
                setShow(true);
            });
        }
        props.setLoading(false);
    }

    //#region Métodos de ciclo de vida
    const onFillGrid = async (Valor, x, y) => {
        props.setLoading(true);
        if (props.bookType === "Compras" || props.bookType === "Ventas") {
            // Getting data from Xero and building data grid
            await getAndBuildGridDataReports(props.orgIdSelected, Valor, x, y).then(result => {
                const reportData = result.map((reportData) => {
                    return {
                        ID: reportData._id,
                        init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                        end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                        download_rep:
                            <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRenderer(props.bookType, reportData._id)}>
                                    <GetAppIcon color="primary" />
                                </IconButton>
                            </LightTooltip>,
                        update_rep:
                            <LightTooltip title={t("reports.updateReport")} aria-label="actualizar">
                                <IconButton aria-label="actualizar" size="small" onClick={() => updateFileColumnRenderer(props.bookType, reportData._id)}>
                                    <UpdateIcon color="primary" />
                                </IconButton>
                            </LightTooltip>
                    };
                });
                // Setting component state
                setrowData(reportData);
            });
        }
        props.setLoading(false);
    }

    /// Funcion utilizada para obtener el periodo y enviar los parámetros
    /// solicitados por medio de post para generar el guardado en Xero
    const onGetPeriodo = async () => {
        props.setLoading(true);
        let resp = false;
        let x = moment(document.getElementById("dtpkDesde" + props.bookType).value, t("language.dateFormat")).format("DD/MM/YYYY");
        let y = moment(document.getElementById("dtpkHasta" + props.bookType).value, t("language.dateFormat")).format("DD/MM/YYYY");
        let z = Value;

        switch (props.bookType) {
            case "Ventas":
            case "Compras":
                let taxbookId = await calls.getBook(
                    props.orgIdSelected,
                    props.bookType === "Ventas" ? 1 : 2,
                    x,
                    y,
                    props.bookType === "Ventas" ? "/salesBook" : "/purchasesBook"
                );

                if (taxbookId.data === false || taxbookId.data === undefined) {
                    setTexto("Ocurrió un problema al momento de generar el libro");
                    setalertType("error");
                    setShow(true);
                }
                else {
                    onDownloadFile(taxbookId.data);
                    onFillGrid("GridUI", x, y);
                    setTexto(t("reports.bookSuccess"));
                    setalertType("success");
                    setShow(true);
                }
                break;
            case "retIVA":
                resp = `/generateSupplierIVAReport?id_organisation=${props.orgIdSelected}&initialDate=${x}&endDate=${y}&contact_id=${z}`;
                if (resp !== false) {
                    let element = document.createElement('a');
                    element.setAttribute('href', resp);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }
                break;
            case "retISLR":
                resp = `/generateSupplierISLRReport?id_organisation=${props.orgIdSelected}&initialDate=${x}&endDate=${y}&contact_id=${z}`;
                if (resp !== false) {
                    let elementI = document.createElement('a');
                    elementI.setAttribute('href', resp);
                    elementI.style.display = 'none';
                    document.body.appendChild(elementI);
                    elementI.click();
                    document.body.removeChild(elementI);
                }
                break;
            default:
                break;
        }
        props.setLoading(false);
    };

    /// @param {string} show - mostrar el modal o no
    const manageModalShow = (show) => {
        setmodalShow(show);
    };

    /* Funciones Libro */
    const handleChangeStartDate = date => {
        let startDate = moment(date, t("language.dateFormat")).format("DD/MM/YYYY");
        let endDate = moment(document.getElementById("dtpkHasta" + props.bookType).value, t("language.dateFormat")).format("DD/MM/YYYY");
        if (util.compareDates(startDate, endDate) === 1) {
            setTexto(t("reports.initDateMess"));
            setalertType("warning");
            setShow(true);
            setisActive(true);
        } else {
            setstartDate(date);
            setisActive(false);
        }
    };

    const handleChangeFinishDate = date => {
        let endDate = moment(date, t("language.dateFormat")).format("DD/MM/YYYY");
        let StartDate = moment(document.getElementById("dtpkDesde" + props.bookType).value, t("language.dateFormat")).format("DD/MM/YYYY");

        if (util.compareDates(endDate, StartDate) === -1) {
            setTexto(t("reports.endDateMess"));
            setalertType("warning");
            setShow(true);
            setisActive(true);
        } else {
            setfinishDate(date);
            setisActive(false);
        }
    };

    const handleClose = () => {
        setmodalShow(false);
    }

    /// Funcion utilizada para generar el archivo obteniendo desde 
    /// un get en base64 el archivo generado
    /// @param {text} origen - Texto para identificar de donde proviene el llamado
    const onDownloadFile = async(taxbookId) => {
        let resp = false;
        console.log("Downloading... " + taxbookId.substring(1, taxbookId.length - 1));
        switch (props.bookType) {
            case "Ventas":
                resp = "/generateSalesBook?taxbookId=" + taxbookId.substring(1, taxbookId.length - 1);
                break;

            case "Compras":
                resp = "/generatePurchasesBook?taxbookId=" + taxbookId.substring(1, taxbookId.length - 1);
                break;

            default:
                break;
        }

        if (resp !== false) {
            var element = document.createElement('a');
            element.setAttribute('href', resp);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        else {
            setTexto(t("manageAccount.dnldError"));
            setalertType("warning");
            setShow(true);
        }
    }

    let boton = (
        <>
            <Button onClick={handleClose} color="primary" variant="outlined" className="cancelBtn">
                {t("miscellaneous.cancel")}
            </Button>
            <Button
                id="btnGenerar"
                disabled={isActive}
                color="primary" variant="contained"
                className={isActive ? "ButtonDisabled" : "btnRoot"}
                onClick={() => {
                    onGetPeriodo();
                    manageModalShow(false, props.bookType);
                }}
                disableElevation
            >
                {t("reports.generate")}
            </Button>
        </>
    )

    let content = (
        <GridUI
            container
            direction="row"
            spacing={2} justifyContent="center" alignItems="center"
        >
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={t("manageAccount.dateFormat")}>
                <GridUI item>
                    <DatePicker
                        autoOk
                        id={`dtpkDesde${props.bookType}`}
                        value={startDate}
                        onChange={handleChangeStartDate}
                        format={t("language.dateFormat")}
                        variant="inline"
                        label={t("reports.initDate")}
                    />
                </GridUI>
                <GridUI item>
                    <DatePicker
                        autoOk
                        id={`dtpkHasta${props.bookType}`}
                        value={finishDate}
                        onChange={handleChangeFinishDate}
                        format={t("language.dateFormat")}
                        variant="inline"
                        label={t("reports.endDate")}
                    />
                </GridUI>
            </MuiPickersUtilsProvider>
        </GridUI>
    )

    return (
        <>
            {/** Botón para generar un periodo específico de libro */}
            <GridUI
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                {props.bookType === "Compras" || props.bookType === "Ventas" ?
                    <GridUI item>
                        <LightTooltip title={props.bookType === "Compras" ? t("reports.bookBillGene") : t("reports.bookInvoiceGene")}>
                            <IconButton aria-label="calendar" size="small" onClick={(event) => manageModalShow(true, props.bookType)}>
                                <EventAvailableIcon fontSize="large" color="primary" />
                            </IconButton>
                        </LightTooltip>
                    </GridUI>
                    : <>
                        <GridUI item xs>
                            <GridUI container justifyContent="flex-start" spacing={3}
                                alignItems="center">
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={t("manageAccount.dateFormat")}>
                                    <GridUI item xs={12} md={2} lg={2}>
                                        <DatePicker
                                            autoOk
                                            id={`dtpkDesde${props.bookType}`}
                                            fullWidth
                                            value={startDate}
                                            onChange={handleChangeStartDate}
                                            format={t("language.dateFormat")}
                                            variant="inline"
                                            label={t("reports.initDate")}
                                        />
                                    </GridUI>
                                    <GridUI item xs={12} md={2} lg={2}>
                                        <DatePicker
                                            autoOk
                                            id={`dtpkHasta${props.bookType}`}
                                            fullWidth
                                            value={finishDate}
                                            onChange={handleChangeFinishDate}
                                            format={t("language.dateFormat")}
                                            variant="inline"
                                            label={props.bookType !== "balanceGenBook" ? t("reports.endDate") : t("miscellaneous.date")}
                                        />
                                    </GridUI>
                                    <GridUI item xs={12} md={3} lg={3}>
                                        <InputLabel shrink id="select-label">
                                            {t("miscellaneous.supplier")}
                                        </InputLabel>
                                        <Select
                                            id={`ContactID${props.bookType}`}
                                            labelId="select-label"
                                            fullWidth
                                            value={Value}
                                            onChange={handleClick}
                                            placeholder={t("miscellaneous.selectOption")}
                                            displayEmpty
                                            IconComponent={CustomExpandMore}
                                            className="ddlAdminPanel"
                                            MenuProps={{
                                                classes: {
                                                    paper: "selectPaperAdmin",
                                                }
                                            }}
                                        >
                                            <MenuItem value="" disabled className="ddlAdminPanel">
                                                {t("miscellaneous.selectOption")}
                                            </MenuItem>
                                            {group.map((contact) =>
                                                <MenuItem value={contact.contact_id} className="ddlAdminPanel">{contact.contact_name}</MenuItem>
                                            )}
                                        </Select>
                                    </GridUI>
                                </MuiPickersUtilsProvider>
                            </GridUI>
                        </GridUI>
                        <GridUI item>
                            <Button
                                variant="contained"
                                id="btnGenerar"
                                color="primary"
                                className="ButtonReports"
                                onClick={() => onGetPeriodo()}
                                disableElevation
                            >
                                {t("reports.generate")}
                            </Button>
                        </GridUI>
                    </>}
            </GridUI>

            {/** Tabla de datos de libros generados por periodo */}
            {props.bookType === "Compras" || props.bookType === "Ventas" ?
                <Grid
                    rows={rowData}
                    columns={columnDefs}
                >
                    <PagingState
                        defaultCurrentPage={0}
                        pageSize={10}
                    />
                    <IntegratedPaging />
                    <Table
                        tableComponent={TableComponent}
                        messages={tableMessages}
                        cellComponent={TableCellComponent}
                        columnExtensions={tableColumnExtensions}
                    />
                    <TableHeaderRow
                        cellComponent={tableHeaderCell}
                        contentComponent={tableContentComponent}
                    />
                    <PagingPanel messages={pagingMessages} />
                </Grid>
                : null}

            {/** Cuadro de diálogo para mostrar la opción de generar un libro por un periodo específico */}
            <AlertDialog
                open={modalShow}
                onClose={handleClose}
                message={content}
                title={t("reports.selectPeriod")}
                button={boton}
                noBackDrop={false}
            />
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleClose2}
            />
        </>
    );
}

export default Reports;
