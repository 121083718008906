/** React imports */
import React, { useState } from "react";

// Admin panel CSS file
import "./Css/adminpanel.css";

/** Components ----------------------------------------------------------- */
import { ReactComponent as OrganizationDetailsIcon } from '../../Imagenes/adminODTopic.svg';
import { ReactComponent as ArrowDownIcon } from '../../Imagenes/arrow-down-icon.svg';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Backdrop from "./Backdrop";
import SnackBar from "./SnackBar";
import DialogForm from "./Dialog";
import InputFile from "./accordion/InputFile";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// Variation
import moment from "moment";
import MomentUtils from "@date-io/moment";
import calls from "../Js/calls";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    centeredContainer: {
        maxWidth: '700px',
        margin: '0 auto'
    },
    cardRoot: {
        borderRadius: 10,
        border: '2px solid #4a22d4',
        height: '100%',
        '&:hover': {
            borderRadius: '10px',
            border: '2px solid #6e4edd',
            cursor: 'pointer',
        }
    },
    link: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    cardTopicRoot: {
        borderRadius: '10px',
        border: '2px solid #4a22d4',
        boxShadow: '10px 10px 5px 0px rgba(74,34,212,0.1)',
        marginBottom: 40
    },
    cardTopicHeader: {
        backgroundColor: '#4a22d4',
        color: '#fff',
        height: 70,
    },
    cardTopicContent: {
        padding: '40px 25px',
    },
    cardTopicActions: {
        float: 'right',
        padding: '30px 25px 40px',
    },
    topicItem: {
        padding: '1.5em 1.5em 1em',
    },
    topicItemTitle: {
        marginBottom: 15,
    },
    topicImg: {
        width: '40px',
    },
    btnRoot: {
        borderRadius: 10,
        border: '1px solid #4a22d4',
        '&:hover': {
            backgroundColor: '#6e4edd',
            border: '1px solid #6e4edd',
        },
    },
    btnCancelRoot: {
        borderRadius: 10,
        border: '2px solid',
        marginRight: 20,
        '&:hover': {
            borderRadius: '10px',
            border: '2px solid #6e4edd',
            color: '#6e4edd',
        },
    },
    formField: {
        marginBottom: 20,
    },
    formField3: {
        marginBottom: 60,
    },
    formFieldLabel: {
        height: 40,
    },
    formFieldLabel2: {
        height: 55,
    },
    formFieldLabel3: {
        [theme.breakpoints.down('sm')]: {
            height: 0,
        },
        [theme.breakpoints.up('md')]: {
            height: 15,
        },
    },
    formFieldInput: {
        height: 40,
    },
    formFieldInput2: {
        height: 80,
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px'
        },
    },
    inputField: {
        fontSize: 15,
        '&:hover': {
            color: '#4a22d4 !important',
        },
        '&:before': {
            borderBottomColor: '#131f3e',
        },
        '&:hover:before': {
            borderBottomColor: '#4a22d4 !important',
        },
        '&:after': {
            borderBottomColor: '#131f3e',
        },
    },
}));

/** Global variables ----------------------------------------------------------- */
var group = [];

const PurpleSwitch = withStyles((theme) => ({
    root: {
        width: 40,
        height: 20,
        padding: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#4a22d4',
                border: '2px solid #4a22d4',
                opacity: 1,

            },
        },
    },
    thumb: {
        width: 18,
        height: 18,
        border: '2px solid #4a22d4',
    },
    track: {
        borderRadius: 10,
        border: '2px solid #4a22d4',
        backgroundColor: '#fff',
        transition: theme.transitions.create(['background-color', 'border']),
        opacity: 1,
    },
    checked: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

//Función utilizada obtener todas las prácticas
async function getAllPractices() {
    return await calls.getAllPractices();
}

const iconStyles = theme => ({
    selectIcon: {
        [theme.breakpoints.up('xl')]: {
            height: '25px',
        },
        [theme.breakpoints.down('lg')]: {
            height: '25px',
        },
    }
});

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ArrowDownIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

/// Admin panel component
export default function AdminPanel(props) {
    /** Component states */
    const todos = useSelector(state => state.todos);
    const [title, setTitle] = useState("");
    const [nomSuper, setnomSuper] = useState("");
    const [nomCodCont, setnomCodCont] = useState("");
    const [correoSuper, setcorreoSuper] = useState("");
    const [nomResp, setnomResp] = useState("");
    const [correoResp, setcorreoResp] = useState("");
    const [selectedDate, handleDateChange] = useState(new Date());
    // Manages loading showing
    const [loading, showLoading] = useState(false);
    // Manages settingsPanel render
    const [settingsPanel, showSettingsPanel] = useState(false);
    // Manages topicsPanel render
    const [topicsPanel, showTopicsPanel] = useState(true);
    // Manages breadcrumb render
    const [breadcrumb, showBreadcrumb] = useState(false);
    // Manages breadcrumb topic
    const [breadcrumbTopic, setBreadcrumbTopic] = useState("");
    // Manages topic settings panel render
    const [topicSettingsPanel, showTopicSettingsPanel] = useState("");
    // Manages taxes module render
    const [converEstCuen, showConverEst] = useState(false);
    // Manages taxes module render
    const [emiRepLegal, showRepLeg] = useState(false);
    // Manages taxes module render
    const [taxesModuleSubgroup, showTaxesModuleSubgroup] = useState(todos.taxes);
    // Manages is agent toggle switch
    const [hasSuscription, setIsSuscriptionToggleSwitch] = useState(false);
    const [Value, setValue] = useState("");
    const [ValueGroup, setValueGroup] = useState("");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, seAutoAlertType] = useState("");
    const [open2, setDialogForm2] = useState(false);
    const classes = useStyles();
    const [t] = useTranslation("global");
    moment.locale(t("manageAccount.dateFormat"));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    //Cambia el estatus del evento del clic en el DeopDownList
    const handleClick = (event, value) => {
        setValue(event.target.value);
        setValueGroup(event.target.value)
    };

    // ----------------------------------------------------
    /** Component events */

    /// Shows topic settgins panel
    /// @param {object} event - DOM event
    const onTopicItemClick = async (event) => {

        getAllPractices().then((result) => {
            group = result;
        });

        // Showing settings panel
        const topicSettings = event.currentTarget.getAttribute("data-settings");
        showTopicSettingsPanel(topicSettings);
        // Setting and showing breadcrumb

        if (topicSettings === "organizationDetails") {
            getAdmminInfo();
            setBreadcrumbTopic(t("adminPanel.orgDetails"));
            showBreadcrumb(true);
        }
    };

    /// Shows topics panel and reset breadcrumb
    /// @param {object} event - DOM event
    const onBreadcrumbRootClick = async () => {
        // Reseting and hiding breadcrumb
        setBreadcrumbTopic("");
        showBreadcrumb(false);

        // Showing topics panel and hiding settings panel
        showTopicsPanel(true);
        showSettingsPanel(false);
        showTopicSettingsPanel("");
        setShow(false);
    };

    /// Handles toggle switch taxes module showing
    /// @param {object} event - DOM event
    const onToggleSwitchRepLegalModuleClick = (event) => {
        // Showing taxes module subgroup
        showRepLeg(event.target.checked);
    };

    // Gets SENIAT info using a service
    const getAdmminInfo = async () => {
        showLoading(true);
        await fetch(`/getOrganisationInfo?id_organisation=${todos.orgID}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => res.json())
            .then((OrgResponse) => {
                // If razonnSocial is empty means the service did not recover any info
                if (OrgResponse.practiceId) {
                    if (OrgResponse.inGroup) {
                        document.getElementById("txtCodCon").style.display = "block";
                        setnomCodCont(OrgResponse.accountCode);
                    }
                    setIsSuscriptionToggleSwitch(OrgResponse.group);
                    showConverEst(OrgResponse.bank_statements);
                    showRepLeg(OrgResponse.legal_reports);
                    // Returning promise
                    setAdminPanelInfo(OrgResponse);
                }
                showLoading(false);
            })
            .catch((error) => {
                console.log(error);
                // Hiding loading    
                showLoading(false);
            });
    };

    /**
     * Sets the info requested robot service that requests Admin info
     * @param {object} info - a Admin info returned by service
     */
    const setAdminPanelInfo = (info) => {
        if (document.getElementById("txtSupervisor") !== null) {
            // Setting all fields data from Seniat panel
            setnomSuper(info.administratorName === null || info.administratorName === undefined ? "" : info.administratorName);
            setcorreoSuper(info.administratorEmail === null || info.administratorEmail === undefined ? "" : info.administratorEmail);
            setnomResp(info.clientName === null || info.clientName === undefined ? "" : info.clientName);
            setcorreoResp(info.clientEmail === null || info.clientEmail === undefined ? "" : info.clientEmail);
            handleDateChange(info.initialDateTaxes === null || info.initialDateTaxes === undefined ? new Date() : moment(info.initialDateTaxes, 'DD/MM/YYYY', true).format());
        }

        setShow(false);
        setValue(todos.practiceID);
    };

    const saveInfoAdminPanel = async () => {
        showLoading(true);
        let isValid = true;
        let valores = [];
        let valoresArray = {};
        let sello = undefined;
        let firma = undefined;
        valoresArray.id_organisation = todos.orgID;
        valoresArray.group = hasSuscription;
        valoresArray.practice_id = ValueGroup === "" ? todos.practiceID : ValueGroup;
        valoresArray.taxes = taxesModuleSubgroup;
        valoresArray.bank_statements = converEstCuen;
        valoresArray.legal_reports = emiRepLegal;

        if (taxesModuleSubgroup) {
            sello = document.getElementById("fileSell").files[0];
            firma = document.getElementById("fileSignature").files[0];
        }

        if (isValid) {
            valoresArray.administrator_name = nomSuper;
            valoresArray.administrator_email = correoSuper;
            valoresArray.client_name = nomResp;
            valoresArray.client_email = correoResp;
            valoresArray.initial_date_taxes = moment(selectedDate).format("DD/MM/YYYY");
            valoresArray.account_code = nomCodCont;

            valores.push(valoresArray);

            let valido = true;
            if (sello !== undefined) {
                let thisExt = sello.name.split('.')[1].toLowerCase();
                if (thisExt !== 'jpg' && thisExt !== 'png' && thisExt !== 'jpeg') {
                    setShow(true);
                    setTexto(t("reports.invalidExt"));
                    seAutoAlertType("warning");
                    valido = false;
                }
                else {
                    var data = new FormData();
                    data.append("file", sello);
                    data.append("id_organisation", todos.orgID);
                    await calls.uploadAdminFiles('/uploadStamp', data);
                }
            }
            if (firma !== undefined) {
                let thisExt = sello.name.split('.')[1].toLowerCase();
                if (thisExt !== 'jpg' && thisExt !== 'png' && thisExt !== 'jpeg') {
                    setShow(true);
                    setTexto(t("reports.invalidExt"));
                    seAutoAlertType("warning");
                    valido = false;
                }
                else {
                    var data2 = new FormData();
                    data2.append("file", firma);
                    data2.append("id_organisation", todos.orgID);
                    await calls.uploadAdminFiles('/uploadSignature', data2);
                }
            }
            if (valido) {
                let save = await calls.updateOrganisation(valores);
                if (save === true) {
                    setTexto(t("adminPanel.infoSaved"));
                    seAutoAlertType("success");
                    setShow(true);
                }
                else {
                    setTexto(t("adminPanel.dataError"));
                    seAutoAlertType("error");
                    setShow(true);
                }
            }
        }
        else {
            setTexto(t("adminPanel.requiredField"));
            seAutoAlertType("warning");
            setShow(true);
        }

        if (taxesModuleSubgroup === true) {
            // Showing taxes module subgroup
            showTaxesModuleSubgroup(taxesModuleSubgroup);
        }
        else {
            showTaxesModuleSubgroup(false);
        }
        showLoading(false);
    };

    const saveInfoAdminPanelGroup = async () => {
        showLoading(true);
        let valor = document.getElementById("txtAddGroup").value;
        if (valor !== "") {
            let guardo = await calls.addPractice(valor);

            if (guardo === true) {
                group = await calls.getAllPractices();

                group.forEach((item) => {
                    if (item.name === valor) {
                        valor = item.id;
                    }
                });
                setValue(valor);

                document.getElementById("txtAddGroup").value = "";
                setDialogForm2(false);

                setTexto(t("adminPanel.infoSaved"));
                seAutoAlertType("success");
                setShow(true);
            }
            else {
                setTexto(t("miscellaneous.existsGroup"));
                seAutoAlertType("warning");
                setShow(true);
            }
        }
        else {
            setTexto(t("miscellaneous.requiredGroup"));
            seAutoAlertType("warning");
            setShow(true);
        }

        showLoading(false);
    };

    // ----------------------------------------------------

    // Defining settings to show
    let settingsForm = "";

    switch (topicSettingsPanel) {
        case "organizationDetails":
            settingsForm = [
                <Card className={classes.cardTopicRoot}>
                    <CardHeader
                        classes={{ root: classes.cardTopicHeader }}
                        title={t("adminPanel.basicInfo")}
                        titleTypographyProps={{ variant: "h1" }}
                    />
                    <CardContent classes={{ root: classes.cardTopicContent }} >
                        <Grid spacing={3} container className={`${classes.formField}`}>
                            <Grid id="txtCodCon" item xs={12} md={6} style={{ display: "none" }}>
                                <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                    <Typography variant="h3" color="textPrimary">{t("adminPanel.contactCode")}</Typography>
                                </Grid>
                                <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                    <TextField id="txtCodigoContact" placeholder="123" fullWidth value={nomCodCont}
                                        onChange={event => setnomCodCont(event.target.value)} inputProps={{ maxLength: 3 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid spacing={3} container className={`${classes.formField}`}>
                            <Grid item xs={12} md={6}>
                                <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                    <Typography variant="h3" color="textPrimary">{t("miscellaneous.group")}</Typography>
                                </Grid>
                                <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                    <Select
                                        id="practices"
                                        value={Value}
                                        onChange={handleClick}
                                        placeholder={t("miscellaneous.selectOption")}
                                        fullWidth
                                        displayEmpty
                                        className="ddlAdminPanel"
                                        IconComponent={CustomExpandMore}
                                        MenuProps={{
                                            classes: {
                                                paper: "selectPaperAdmin",
                                            }
                                        }}
                                    >
                                        <MenuItem value="" disabled className="ddlAdminPanel">
                                            {t("miscellaneous.selectOption")}
                                        </MenuItem>
                                        {group.map((xero) =>
                                            <MenuItem value={xero.id} className="ddlAdminPanel">{xero.name}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid item xs={12} className={`${classes.formFieldLabel3}`}>
                                </Grid>
                                <Grid item xs={12}>
                                    <LightTooltip title={t("miscellaneous.addGroup")} aria-label="cargar">
                                        <IconButton onClick={() => {
                                            setDialogForm2(true);
                                            setTitle(t("adminPanel.newGroup"));
                                        }}>
                                            <AddBoxOutlinedIcon color="primary" fontSize="large" />
                                        </IconButton>
                                    </LightTooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid spacing={3} container className={`${classes.formField}`}>
                            <Grid item xs={12} md={6}>
                                <Grid item xs={12} className={`${classes.formFieldLabel2}`}>
                                    <Typography variant="h3" color="textPrimary">
                                        {t("adminPanel.legalReport")}
                                    </Typography>
                                </Grid>
                                <Grid item md={4} className={`${classes.formFieldInput}`}>
                                    <PurpleSwitch
                                        checked={emiRepLegal}
                                        onClick={onToggleSwitchRepLegalModuleClick}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/** Topic settings form subgroup */}
                        {/** When user enables Taxes module to organization */}
                        {taxesModuleSubgroup ? (
                            <>
                                <Grid spacing={3} container className={`${classes.formField}`}>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                            <Typography variant="h3" color="textPrimary">{t("adminPanel.supervisorName")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                            <TextField id="txtSupervisor" placeholder="Juan Pérez" value={nomSuper} fullWidth
                                                onChange={event => setnomSuper(event.target.value)} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                            <Typography variant="h3" color="textPrimary">{t("adminPanel.supervisorEmail")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                            <TextField id="txtEmail" type="email" placeholder="juan.perez@gmail.com" value={correoSuper}
                                                onChange={event => setcorreoSuper(event.target.value)} fullWidth />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid spacing={3} container className={`${classes.formField}`}>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                            <Typography variant="h3" color="textPrimary">{t("adminPanel.inCharge")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                            <TextField id="txtResponsable" placeholder="Juan Pérez" fullWidth value={nomResp}
                                                onChange={event => setnomResp(event.target.value)} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                            <Typography variant="h3" color="textPrimary">{t("adminPanel.inChargeEmail")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                            <TextField id="txtEmailResp" type="email" placeholder="juan.perez@gmail.com" fullWidth value={correoResp}
                                                onChange={event => setcorreoResp(event.target.value)} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/** Topic settings form group */}
                                <Grid spacing={3} container className={`${classes.formField}`}>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                            <Typography variant="h3" color="textPrimary">{t("adminPanel.initDateCal")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={t("manageAccount.dateFormat")}>
                                                <DatePicker
                                                    autoOk
                                                    id="dateCalc"
                                                    format={t("language.dateFormat")}
                                                    variant="inline"
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    fullWidth
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/** Topic settings form group */}
                                <Grid spacing={3} container className={`${classes.formField3}`}>
                                    <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                        <Typography variant="h2" color="primary">{t("adminPanel.others")}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid spacing={3} container className={`${classes.formField}`}>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                            <Typography variant="h3" color="textPrimary">{t("adminPanel.stamp")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                            <InputFile id="fileSell" tooltip={t("adminPanel.validFormat")} place="bottom" />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12} className={`${classes.formFieldLabel}`}>
                                            <Typography variant="h3" color="textPrimary">{t("adminPanel.digiLegalRep")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={`${classes.formFieldInput}`}>
                                            <InputFile id="fileSignature" tooltip={t("adminPanel.validFormat")} place="bottom" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ) : null}
                    </CardContent>
                    <CardActions classes={{ root: classes.cardTopicActions }}>
                        <Button variant="outlined" color="primary" classes={{ root: classes.btnCancelRoot }} onClick={() => onBreadcrumbRootClick()}>{t("miscellaneous.cancel")}</Button>
                        <Button variant="contained" color="primary" onClick={(event) => saveInfoAdminPanel(event)} classes={{ root: classes.btnRoot }} disableElevation>{t("miscellaneous.save")}</Button>
                    </CardActions>
                </Card>
            ];
            break;
        default:
            break;
    }

    let contentGrupo = (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <TextField id="txtAddGroup" label={t("miscellaneous.group")} fullWidth />
            </Grid>
        </Grid>
    )

    let botonesGrupo = (
        <>
            <Button onClick={() => setDialogForm2(false)} color="primary" variant="outlined" className="cancelBtn">
                {t("miscellaneous.cancel")}
            </Button>
            <Button onClick={() => saveInfoAdminPanelGroup()} color="primary" variant="contained"
                className="btnRoot" disableElevation>
                {t("miscellaneous.accept")}
            </Button>
        </>
    )

    // Component render
    return (
        // Main admin workspace
        <div className="group">
            {/** Workspace header */}
            {/** Workspace title */}
            <div id="adminPanelTitle">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Typography variant="h1" color="textSecondary" className={classes.link} onClick={onBreadcrumbRootClick}>
                        {t("miscellaneous.settings")}
                    </Typography>
                    {breadcrumb ? <Typography variant="h1" color="textSecondary">{breadcrumbTopic}</Typography> : null}
                </Breadcrumbs>
            </div>

            {/** Workspace body */}
            <div id="adminPanelBody">
                {/** Panel where settings topics are displayed */}
                {topicsPanel ? (
                    <Grid container justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item xl={3} lg={4} xs={12}>
                            <Card variant="outlined" className={classes.cardRoot}>
                                <CardContent>
                                    <div
                                        className={classes.topicItem}
                                        data-settings="organizationDetails"
                                        onClick={(event) => {
                                            // Showing settings panel, hiding topics panel
                                            showTopicsPanel(false);
                                            showSettingsPanel(true);
                                            onTopicItemClick(event);
                                        }}
                                    >
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} lg={4} xl={2}>
                                                <OrganizationDetailsIcon className={classes.topicImg} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4" color="primary" className={classes.topicItemTitle}>
                                                    {t("adminPanel.orgDetails")}
                                                </Typography>
                                                <Typography variant="body1" color="textPrimary" > {t("miscellaneous.group")} </Typography>
                                                <Typography variant="body1" color="textPrimary" > Supervisor </Typography>
                                                <Typography variant="body1" color="textPrimary" > {t("adminPanel.stamp")} </Typography>
                                                <Typography variant="body1" color="textPrimary" >...</Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                ) : null}

                {/** Panel where settings forms are displayed  according to topic choice */}
                {settingsPanel ? (
                    <section id="settingsSection">
                        <div className={classes.centeredContainer}>
                            {settingsForm}
                        </div>
                    </section>
                ) : null}
            </div>
            <Backdrop open={loading} />
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleClose}
            />
            <DialogForm
                open={open2}
                onClose={() => setDialogForm2(false)}
                title={title}
                message={contentGrupo}
                button={botonesGrupo}
                noBackDrop={true}
            />
        </div >
    );
}