import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    title: {
        backgroundColor: "#4a22d4",
        '& h2': {
            fontSize: "18px",
            color: "#fff"
        },
    },
    content: {
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    actions: {
        paddingBottom: "40px",
    },
    paper: {
        borderRadius: "10px",
        boxShadow: '10px 10px 15px rgba(74, 34, 212, 0.3)'
    }
}));

export default function AlertDialog(props) {
    const classes = styles();
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick={props.noBackDrop}
                disableEscapeKeyDown={props.noBackDrop}
                PaperProps={{ classes: {root: classes.paper} }}
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title" className={classes.title}>{props.title}</DialogTitle>
                <DialogContent className={classes.content}>
                    <DialogContentText id="alert-dialog-description">
                        {props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    {props.button}
                </DialogActions>
            </Dialog>
        </div>
    );
}