import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';

//Imágenes
import { ReactComponent as KiiperLogo } from "../Imagenes/kiiper-logo.svg";
import { ReactComponent as ConnectButton } from "../Imagenes/connect-white.svg";

//Componentes
import Backdrop from "./internos/Backdrop";
import calls from "./Js/calls";
import Cookies from "js-cookie";

//Controles
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

/** Component styles */
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '20%',
        },
        [theme.breakpoints.only('sm')]: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '30%',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '60%',
        },
    },
    loginSubtitle: {
        [theme.breakpoints.up('xl')]: {
            fontSize: '230%',
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: '187.5%',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '125%',
        },
    },
    loginContainer: {
        height: '80%',
    }
}));

const Login = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();

    /** Component states */
    const [accessToken] = useState(Cookies.get("accessToken"));

    const [isInActive, setIsInActive] = useState(true);

    const [showBackdrop, setBackdrop] = useState(false);

    

    /// Main Login component function
    useEffect(() => {
        if (accessToken) {
            dispatch({
                type: 'addToken',
                item: accessToken
            })
            // Defining state of Login component in order to show
            // the content because of [isInActive = false]
            const fetchdata = async () => {
                let grupo = await calls.getGroupsList();
                if (grupo !== undefined) {
                    setIsInActive(false);
                }
                else {
                    dispatch({
                        type: 'clearInfo'
                    })
                }
            }
            fetchdata();
        }
    }, [accessToken, dispatch]);

    // Starts process to login into Xero
    const accesToXero = async () => {
        // Go and open the login page to Xero
        setBackdrop(true);

        fetch('/getConsentUrl')
            .then(res => res.json())
            .then(consentUrl => window.open(consentUrl, "_self"))
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className="login-body">
            <Backdrop
                open={showBackdrop}
            />
            {isInActive ?
                <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.loginContainer}>
                    <Grid item xs={12} md={12} lg={12}>
                        <KiiperLogo className={classes.root} />
                        <Typography variant="subtitle1" align="center" color="secondary" className={classes.loginSubtitle}> {t("login.routinesDone")} </Typography>
                        <div className="xero-connect-button">
                            <LightTooltip title={t("login.login")}>
                                <Button>
                                    <ConnectButton onClick={() => accesToXero()} style={{ width: '90%' }} />
                                </Button>
                            </LightTooltip>
                        </div>
                    </Grid>
                </Grid >
                : <Redirect to='/loading' />}
        </ div>
    )
}

export default Login;