import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import util from "../../Js/util";
import calls from "../../Js/calls";

//Componentes
import SnackBar from "../../internos/SnackBar";
import Backdrop from "../../internos/Backdrop";
import GridUI from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from "@material-ui/core/Container";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ReactComponent as ArrowDownIcon } from '../../../Imagenes/arrow-down-icon.svg';
import { SelectionState, PagingState, IntegratedPaging, IntegratedSelection } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableSelection, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import moment from "moment";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
}))(Tooltip);

const tableStyles = theme => ({
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: '#faf8fe',
        },
    },
    tableHeader: {
        border: 'none',
        backgroundColor: '#e4def9',
        fontSize: '15px',
        fontWeight: 600,
        color: '#131f3e',
        '&:first-child': {
            borderRadius: '10px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 10px 0 0',
        },
        [theme.breakpoints.up('md')]: {
            whiteSpace: 'pre-wrap',
        },
    },
    tableBody: {
        border: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#131f3e',
    },
});

const tableHeaderSelCellBase = ({ classes, ...props }) => (
    <TableSelection.HeaderCell
        {...props}
        className={classes.tableHeader}
    />
);

const tableHeaderCellBase = ({ classes, ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableComponentBase = ({ classes, ...props }) => (
    <Table.Table
        {...props}
        className={classes.tableStriped}
    />
);

const TableCellComponentBase = ({ classes, ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const TableSelCellComponentBase = ({ classes, ...props }) => (
    <TableSelection.Cell
        {...props}
        className={classes.tableBody}
    />
);

const tableHeaderSelCellBaseDis = ({ classes, ...props }) => (
    <TableSelection.HeaderCell
        {...props}
        className={classes.tableHeader}
        disabled
    />
);

const tableContentComponent = props => (
    <TableHeaderRow.Content {...props} align={'center'} />
);

const tableHeaderSelCell = withStyles(tableStyles, { name: 'tableHeaderSelCell' })(tableHeaderSelCellBase);
const tableHeaderCell = withStyles(tableStyles, { name: 'tableHeaderCell' })(tableHeaderCellBase);
const TableComponent = withStyles(tableStyles, { name: 'TableComponent' })(TableComponentBase);
const TableCellComponent = withStyles(tableStyles, { name: 'TableCellComponent' })(TableCellComponentBase);
const TableSelCellComponent = withStyles(tableStyles, { name: 'TableSelCellComponent' })(TableSelCellComponentBase);
const tableHeaderSelCellDis = withStyles(tableStyles, { name: 'tableHeaderSelCellDis' })(tableHeaderSelCellBaseDis);

const rowSelectionEnabled = row => (row.reissuable === true && row.reissued === false);

class PatchedTableSelection extends React.PureComponent {
    render() {
        const { rowSelectionEnabled, ...restProps } = this.props;
        return (
            <TableSelection
                cellComponent={(props) => this.props.rowSelectionEnabled(props.tableRow.row) ? (
                    <TableSelection.Cell {...props} className="tableBody" />
                ) : (
                    <Table.StubCell {...props} className="tableBody" />
                )}
                {...restProps}
            />
        );
    }
}

const iconStyles = theme => ({
    selectIcon: {
        [theme.breakpoints.up('xl')]: {
            height: '25px',
        },
        [theme.breakpoints.down('lg')]: {
            height: '25px',
        },
    }
});

const CustomTabs = withStyles({
    indicator: {
        backgroundColor: '#4a22d4',
    },
})(Tabs);

const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        marginLeft: theme.spacing(1),
        fontSize: '16px',
        fontFamily: [
            'Goldplay',
            'sans-serif',
        ].join(','),
        '&:hover': {
            color: '#4a22d4',
            opacity: 1,
        },
        '&$selected': {
            color: '#4a22d4',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#4a22d4',
        },
    },
    selected: {},
}))(Tab);

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ArrowDownIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} className="cutomBox">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const Compras = () => {
    const todos = useSelector(state => state.todos);
    const [taxes, setTaxes] = useState(true);
    const [activeItem, setactiveItem] = useState("");
    const [rowData, setrowData] = useState([]);
    const [columnDefs, setcolumnDefs] = useState([]);
    const [columnDefsISLR, setcolumnDefsISLR] = useState([]);
    const [columnDefs2, setcolumnDefs2] = useState([]);
    const [columnDefsISLR2, setcolumnDefsISLR2] = useState([]);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [Tipo, setTipo] = useState("IVA");
    const [event, setEvent] = useState(4.2);
    const [alertType, setalertType] = useState("");
    // const [isSpecialContrib, setisSpecialContrib] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selection, setSelections] = useState([]);
    const [value, setValue] = useState(0);
    const [t] = useTranslation("global");

    const [tableColumnExtensions] = useState([
        { columnName: 'invoice_number', align: 'left' },
        { columnName: 'invoice_control', align: 'left' },
        { columnName: 'contact_name', align: 'left', width: '13%' },
        { columnName: 'invoice_date', align: 'center' },
        { columnName: 'invoice_subtotal', align: 'right', width: '12%' },
        { columnName: 'invoice_total_tax', align: 'right', width: '12%' },
        { columnName: 'retention_percentage', align: 'center' },
        { columnName: 'retained_amount', align: 'right', width: '12%' },
        { columnName: 'approval_date', align: 'center', width: '9%' },
        { columnName: 'correlative', align: 'center', width: '8%' },
        { columnName: 'documento', align: 'center', width: '4%' }
    ]);

    const [tableColumnExtensions2] = useState([
        { columnName: 'invoice_number', align: 'left' },
        { columnName: 'invoice_control', align: 'left' },
        { columnName: 'contact_name', align: 'left', width: '13%' },
        { columnName: 'invoice_date', align: 'center' },
        { columnName: 'invoice_subtotal', align: 'right', width: '10%' },
        { columnName: 'invoice_total_tax', align: 'right', width: '10%' },
        { columnName: 'retention_percentage', align: 'center' },
        { columnName: 'retained_amount', align: 'right', width: '10%' },
        { columnName: 'approval_date', align: 'center', width: '9%' },
        { columnName: 'correlative', align: 'center', width: '8%' },
        { columnName: 'documento', align: 'center', width: '4%' },
        { columnName: 'check', align: 'center', width: '5%' }
    ]);

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ''} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        handleItemClick(newValue);
    };

    const BuildGridData = async (name) => {
        await util.getAndBuildGridData(
            event,
            name,
            "Proveedor",
            todos.orgID
        ).then((result) => {
            const reportData = result.data.map((reData) => {
                return {
                    ID: reData._id,
                    real_invoice_number: reData.invoice_number,
                    invoice_number: reData.invoice_number,
                    invoice_control: reData.invoice_control,
                    contact_name: reData.contact_name,
                    invoice_date: moment(reData.invoice_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                    invoice_subtotal: parseFloat(reData.invoice_subtotal).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    invoice_total_tax: parseFloat(reData.invoice_total_tax).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    retention_percentage: reData.retention_percentage,
                    retained_amount: parseFloat(reData.retained_amount).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    approval_date: moment(reData.approval_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                    correlative: reData.correlative,
                    documento:
                        <LightTooltip title={t("bills.downDoc")} aria-label="descargar">
                            <IconButton aria-label="descargar" size="small" onClick={() => CellRendererDow(reData._id)}>
                                <GetAppIcon color="primary" />
                            </IconButton>
                        </LightTooltip>,
                    check: <IconButton aria-label="aprobado" size="small">
                        {reData.reissued === true ?
                            <CheckCircleIcon color="primary" /> :
                            <CancelIcon color="primary" />
                        }
                    </IconButton>,
                    reissued: reData.reissued,
                    reissuable: reData.reissuable
                }
            });
            // Setting component state
            setrowData(reportData);
            setactiveItem(name);
            setTipo(result.taxInfo.name);
            setEvent(result.taxInfo.event);
        });
    }

    const CellRendererDow = async (ID) => {
        setLoading(true);

        let resp = "/downloadWithholding?withholdingId=" + ID;
        if (resp !== false) {
            var element = document.createElement('a');
            element.setAttribute('href', resp);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        else {
            setTexto(t("manageAccount.dnldError"));
            setalertType("warning");
            setShow(true);
        }

        setLoading(false);
    }

    //#region Métodos de ciclo de vida
    useEffect(() => {
        (async () => {
            setLoading(true);
            const columnDefs = [
                { name: "invoice_number", title: t("bills.numBills") },
                { name: "invoice_control", title: t("bills.numControl") },
                { name: "contact_name", title: t("miscellaneous.supplier") },
                { name: "invoice_date", title: t("bills.dateBill") },
                { name: "invoice_subtotal", title: t("bills.impBase") },
                { name: "invoice_total_tax", title: t("bills.totIva") },
                { name: "retention_percentage", title: t("bills.percDetained") },
                { name: "retained_amount", title: t("bills.itbisRet") },
                { name: "approval_date", title: t("bills.dateComp") },
                { name: "documento", title: " " }
            ];

            const columnDefsISLR = [
                { name: "invoice_number", title: t("bills.numBills") },
                { name: "invoice_control", title: t("bills.numControl") },
                { name: "contact_name", title: t("miscellaneous.supplier") },
                { name: "invoice_date", title: t("bills.dateBill") },
                { name: "invoice_subtotal", title: t("bills.impBase") },
                { name: "retention_percentage", title: t("bills.percDetained") },
                { name: "retained_amount", title: t("bills.isrRet") },
                { name: "approval_date", title: t("bills.dateComp") },
                { name: "documento", title: " " }
            ];

            const columnDefs2 = [
                { name: "invoice_number", title: t("bills.numBills") },
                { name: "invoice_control", title: t("bills.numControl") },
                { name: "contact_name", title: t("miscellaneous.supplier") },
                { name: "invoice_date", title: t("bills.dateBill") },
                { name: "invoice_subtotal", title: t("bills.impBase") },
                { name: "invoice_total_tax", title: t("bills.totIva") },
                { name: "retention_percentage", title: t("bills.percDetained") },
                { name: "retained_amount", title: t("bills.retAmount") },
                { name: "approval_date", title: t("bills.dateComp") },
                { name: "correlative", title: t("bills.numComp") },
                { name: "documento", title: " " },
                { name: "check", title: t("bills.forward") }
            ];

            const columnDefsISLR2 = [
                { name: "invoice_number", title: t("bills.numBills") },
                { name: "invoice_control", title: t("bills.numControl") },
                { name: "contact_name", title: t("miscellaneous.supplier") },
                { name: "invoice_date", title: t("bills.dateBill") },
                { name: "invoice_subtotal", title: t("bills.impBase") },
                { name: "invoice_total_tax", title: t("bills.totIslr") },
                { name: "retained_amount", title: t("bills.retAmount") },
                { name: "approval_date", title: t("bills.dateComp") },
                { name: "correlative", title: t("bills.numComp") },
                { name: "documento", title: " " },
                { name: "check", title: t("bills.forward") }
            ];

            setcolumnDefs(columnDefs);
            setcolumnDefs2(columnDefs2);
            setcolumnDefsISLR(columnDefsISLR);
            setcolumnDefsISLR2(columnDefsISLR2);

            // Getting data from Xero and building data grid
            await returnTaxStatus(todos.orgID).then(async (result) => {
                if (result.data) {
                    await calls.Contribuyenteespecial(todos.orgID).then(async (result) => {
                        await util.getAndBuildGridData(result === true ? "IVA" : "ISLR", "Aprobados", "Proveedor", todos.orgID).then((result2) => {
                            const reportData = result2.data.map((reData) => {
                                return {
                                    ID: reData._id,
                                    real_invoice_number: reData.invoice_number,
                                    invoice_number: reData.invoice_number,
                                    invoice_control: reData.invoice_control,
                                    contact_name: reData.contact_name,
                                    invoice_date: moment(reData.invoice_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                    invoice_subtotal: parseFloat(reData.invoice_subtotal).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                    invoice_total_tax: parseFloat(reData.invoice_total_tax).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                    retention_percentage: reData.retention_percentage,
                                    retained_amount: parseFloat(reData.retained_amount).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                                    approval_date: moment(reData.approval_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                    correlative: reData.correlative,
                                    documento:
                                        <LightTooltip title={t("bills.downDoc")} aria-label="descargar">
                                            <IconButton aria-label="descargar" size="small" onClick={() => CellRendererDow(reData._id)}>
                                                <GetAppIcon color="primary" />
                                            </IconButton>
                                        </LightTooltip>,
                                    check: <IconButton aria-label="aprobado" size="small">
                                        {reData.reissued === true ?
                                            <CheckCircleIcon color="primary" /> :
                                            <CancelIcon color="primary" />
                                        }
                                    </IconButton>,
                                    reissued: reData.reissued,
                                    reissuable: reData.reissuable
                                }
                            });

                            setrowData(reportData);
                            setactiveItem(result2.statusInfo.name);
                            setTipo(result2.taxInfo.name);
                            setEvent(result2.taxInfo.event);
                            // setisSpecialContrib(result);
                        });
                    });
                }
                else {
                    setTexto(t("miscellaneous.notActMod"));
                    setalertType("info");
                    setShow(true);
                }
                setTaxes(result.data);
            });

            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todos.orgID, t]);

    const createDataDrid = (columnDefs, rowData, tableColumnExtensions) => {
        return (
            <Grid
                rows={rowData}
                columns={columnDefs}
            >
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection}
                />
                <PagingState
                    defaultCurrentPage={0}
                    pageSize={10}
                />
                <IntegratedSelection />
                <IntegratedPaging />
                <Table
                    tableComponent={TableComponent}
                    messages={tableMessages}
                    cellComponent={TableCellComponent}
                    columnExtensions={tableColumnExtensions}
                />
                <TableHeaderRow
                    cellComponent={tableHeaderCell}
                    contentComponent={tableContentComponent}
                />
                <TableSelection headerCellComponent={tableHeaderSelCellDis} cellComponent={TableSelCellComponent} showSelectAll />
                <PagingPanel messages={pagingMessages} />
            </Grid>
        )
    }

    const createDataDrid2 = (columnDefs, rowData, tableColumnExtensions) => {
        return (
            <Grid
                rows={rowData}
                columns={columnDefs}
            >
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection}
                />
                <PagingState
                    defaultCurrentPage={0}
                    pageSize={10}
                />
                <IntegratedSelection />
                <IntegratedPaging />
                <Table
                    tableComponent={TableComponent}
                    messages={tableMessages}
                    cellComponent={TableCellComponent}
                    columnExtensions={tableColumnExtensions}
                />
                <TableHeaderRow
                    cellComponent={tableHeaderCell}
                    contentComponent={tableContentComponent}
                />
                <PatchedTableSelection
                    showSelectAll
                    rowSelectionEnabled={rowSelectionEnabled}
                    headerCellComponent={tableHeaderSelCell}
                />
                <PagingPanel messages={pagingMessages} />
            </Grid>
        )
    }

    //Función utilizada para obtener el impuesto de cada organización
    const returnTaxStatus = async (item) => {
        return await calls.returnTaxStatus(item);
    }

    const handleClick = (event) => {
        handleListItemClick(event.target.value);
        setEvent(event.target.value);
    }

    //Función utilizada para cambiar el estado y llenado del grid dependiendo la selección de IVA/ISLR
    const handleListItemClick = async (index) => {
        setLoading(true);
        onRemoveSelected();

        // Getting data from Xero and building data grid
        await util.getAndBuildGridData(
            index,
            activeItem,
            "Proveedor",
            todos.orgID
        )
            .then((result) => {
                const reportData = result.data.map((reData) => {
                    return {
                        ID: reData._id,
                        real_invoice_number: reData.invoice_number,
                        invoice_number: reData.invoice_number,
                        invoice_control: reData.invoice_control,
                        contact_name: reData.contact_name,
                        invoice_date: moment(reData.invoice_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                        invoice_subtotal: parseFloat(reData.invoice_subtotal).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        invoice_total_tax: parseFloat(reData.invoice_total_tax).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        retention_percentage: reData.retention_percentage,
                        retained_amount: parseFloat(reData.retained_amount).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        approval_date: moment(reData.approval_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                        correlative: reData.correlative,
                        documento:
                            <LightTooltip title={t("bills.downDoc")} aria-label="descargar">
                                <IconButton aria-label="descargar" size="small" onClick={() => CellRendererDow(reData._id)}>
                                    <GetAppIcon color="primary" />
                                </IconButton>
                            </LightTooltip>,
                        check: <IconButton aria-label="aprobado" size="small">
                            {reData.reissued === true ?
                                <CheckCircleIcon color="primary" /> :
                                <CancelIcon color="primary" />
                            }
                        </IconButton>,
                        reissued: reData.reissued,
                        reissuable: reData.reissuable
                    }
                });
                // Setting component state
                setrowData(reportData);
                setactiveItem(result.statusInfo.name);
                setTipo(result.taxInfo.name);
                setEvent(result.taxInfo.event);
            });
        setLoading(false);
    };

    //Función utilizada para cambiar el estado y llenado del frid delendiendo del clic en el menu superior del mismo
    const handleItemClick = async (num) => {
        setLoading(true);
        let name = "";
        switch (num) {
            case 0:
                name = "Aprobados"
                break;
            case 1:
                name = "Anulados"
                break;
            default:
                name = "Archivados"
                break;
        }
        // Cleaning rows selected on grid
        onRemoveSelected();

        await BuildGridData(name);

        setLoading(false);
    };

    //Función utilizada para mover los datos de un estatus a otro
    const onMoveData = async (name) => {
        onFillstate(selection, name);
    };

    /// Llena el estado dependiendo delestatus seleccionado
    /// @param {object} gridSelectedRows - Object of selected items in grid
    /// @param {string} statusName - name of status
    const onFillstate = async (gridSelectedRows, statusName) => {
        setLoading(true);
        setactiveItem(statusName.substring(0, statusName.length - 3));

        let result = "";
        let arrayToSend = [];

        // Start proccess to gather all information from grid items selected /
        // Gathering items selected information
        gridSelectedRows.forEach((selectedRow) => {
            // Storing data from items selected in Sales grid
            arrayToSend.push({
                _id: rowData[selectedRow].ID,
            });
        });

        // Defining JSON oject to add to list of voucher to send
        // in voucher view action button
        switch (statusName) {
            case "Recibidos":
            case "Archivados": 
            case "Aprobados":  
                result = await calls.setDataVoidWidthHoldings(arrayToSend);
                if (result === true) {
                    onRemoveSelected();
                    await BuildGridData(statusName);

                    setTexto(t("bills.mesAnul"));
                    setalertType("success");
                    setShow(true);
                    setactiveItem(statusName);
                }

                setLoading(false);
                break;
            case "Anulados":   // Anulados
                let valor = false;
                arrayToSend.forEach(async (withHoldingId) => {
                    // Storing data from items selected in Sales grid
                    result = await calls.setDataReissueWidthHoldings(withHoldingId);

                    if (valor === false) {
                        if (result === true || result === false) {
                            onRemoveSelected();
                            await BuildGridData(statusName);

                            setTexto(t("bills.mesSuccess"));
                            setalertType("success");
                            setShow(true);
                            setactiveItem(statusName);
                        }

                        setLoading(false);
                        valor = true;
                    }
                });
                break;
            default:
                setShow(false);
                setTexto("");
                setLoading(false);
                break;
        }
    };

    //Función on row selected del grid
    const setSelection = (gridSelectedRows) => {
        if (gridSelectedRows.length > 0) {
            switch (activeItem) {
                case "Recibidos":
                case "Archivados":
                case "Aprobados":
                case "Anulados":
                    setactiveItem(activeItem + "Sel");
                    break;
                default:
                    break;
            }
        } else {
            if (activeItem.includes("Sel") === true)
                setactiveItem(activeItem.substring(0, activeItem.length - 3));
            else
                setactiveItem(activeItem);
        }

        setSelections(gridSelectedRows);
    };

    /// Clear selected elements in the grid
    const onRemoveSelected = () => {
        setSelections([]);
    };

    return (
        <Container maxWidth="lg" className="mainPanel">
            <GridUI style={{ display: taxes === true ? "block" : "none" }} container spacing={2} justifyContent="center" alignItems="center">
                <GridUI item xs className="botBreadcrumb">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Typography variant="h1" color="textSecondary">{t("principal.business")}</Typography>
                        <Typography variant="h1" color="textSecondary">{t("principal.bills")}</Typography>
                    </Breadcrumbs>
                </GridUI>
                <GridUI item xs={12}>
                    {/*Pintado del dropdownlist de iva/isrl*/}
                    {/*Si es contribuyente especial pinta IVA e ISLR y si no solo pinta ISLR*/}
                    {/* {isSpecialContrib === true ? ( */}
                    <FormControl variant="outlined" className="formRounded" size="small">
                        <Select
                            value={event}
                            onChange={handleClick}
                            IconComponent={CustomExpandMore}
                            className="ddlVentas"
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                classes: {
                                    paper: "selectPaper",
                                }
                            }}
                        >
                            <MenuItem
                                value={4.1}
                            >
                                <Typography variant="h4" component="span" align="center" color="textPrimary">{t("bills.comRetISLR")}</Typography>
                            </MenuItem>
                            <MenuItem
                                value={4.2}
                            >
                                <Typography variant="h4" component="span" align="center" color="textPrimary">{t("bills.comRetIVA")}</Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>

                    {/*Pintado de grid dependiendo del menu superior del grid*/}

                    <Paper className="salesPaper">
                        <GridUI container alignItems="center">
                            <GridUI item xs={12} sm={10} md={10} lg={11}>
                                <CustomTabs value={value} onChange={handleChange} aria-label="tabs" variant="scrollable" scrollButtons="auto">
                                    <CustomTab label={t("bills.aproved")} {...a11yProps(0)} />
                                    <CustomTab label={t("bills.nulled")} {...a11yProps(1)} />
                                    <CustomTab label={t("bills.archived")} {...a11yProps(2)} />
                                </CustomTabs>
                            </GridUI>
                            <GridUI item xs={12} sm={2} md={2} lg={1} className="two-buttons-container">
                                {activeItem === "Aprobados" || activeItem === "Archivados" ? (
                                    <Button variant="contained" color="primary" className="ButtonDisabled" disabled startIcon={<CloseIcon />}>
                                        {t("bills.cancel")}
                                    </Button>
                                ) : activeItem === "Anulados" ? (
                                    <Button variant="contained" color="primary" className="ButtonDisabled" disabled>
                                        {t("bills.refer")}
                                    </Button>
                                ) : activeItem === "AnuladosSel" ? (
                                    <Button variant="contained" color="primary" className="Button" onClick={() => onMoveData("Anulados")} disableElevation>
                                        {t("bills.refer")}
                                    </Button>
                                ) : activeItem === "AprobadosSel" ? (
                                    <Button variant="contained" color="primary" className="Button" onClick={() => onMoveData("Aprobados")} startIcon={<CloseIcon />} disableElevation>
                                        {t("bills.cancel")}
                                    </Button>
                                ) : activeItem === "ArchivadosSel" ? (
                                    <Button variant="contained" color="primary" className="Button" onClick={() => onMoveData("Archivados")} startIcon={<CloseIcon />} disableElevation>
                                        {t("bills.cancel")}
                                    </Button>
                                ) : null}
                            </GridUI>
                        </GridUI>
                        {/*Pintado de grid dependiendo del flujo de los botones*/}
                        <TabPanel value={value} index={0}>
                            {createDataDrid(
                                Tipo === "IVA" ? columnDefs : columnDefsISLR,
                                rowData,
                                tableColumnExtensions
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {createDataDrid2(
                                Tipo === "IVA" ? columnDefs2 : columnDefsISLR2,
                                rowData,
                                tableColumnExtensions2
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {createDataDrid(
                                Tipo === "IVA" ? columnDefs : columnDefsISLR,
                                rowData,
                                tableColumnExtensions
                            )}
                        </TabPanel>
                    </Paper>
                </GridUI>
            </GridUI>
            <Backdrop open={loading} />
            <SnackBar
                show={show}
                message={texto}
                type={alertType}
                onClose={() => setShow(false)}
            />
        </Container>
    );
}

export default Compras;
