import util from "./util";

/* Script que contiene las llamadas a funciones */
const calls = {
    getSession: () => {
        // Organization selected by user previously
        // Fetch URL with parameters
        const fetchURL = "/checkSession"

        // Fetching data from the endpoint
        return (
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    if (!data) {
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        );
    },

    /// Helps to request and build the data structure to render it
    /// inside the workspace grid. The information represented
    /// corresponds to the different concepts from the organization
    /// selected like: sales, purchases, banks, etc.
    /// @param {integer} taxInfo - tax info with id and name
    /// @param {integer} statusInfo - status info with id and name
    /// @param {string} orgIdSelected - organization id from Xero

    getOrgConceptsInfo: (taxInfo, statusInfo, orgIdSelected) => {
        // Organization selected by user previously
        // Fetch URL with parameters
        const fetchURL =
            "/getWithholdings" +
            `?id_organisation=${orgIdSelected}` +
            `&id_tax_type=${taxInfo.id}` +
            `&id_status=${statusInfo.id}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                        taxInfo: taxInfo,
                        statusInfo: statusInfo,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Call base64 element
    /// @param {string} withholdingId - _Id from xero element
    getDocumentById: (withholdingId) => {
        const fetchConfig = { method: "GET" };

        // Fetch URL with parameters
        const fetchURL = `/downloadWithholding?withholdingId=${withholdingId}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL, fetchConfig)
                .then((res) => {
                    return res.url
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Call base64 element
    /// @param {string} withholdingId - _Id from xero element
    getDeclarationDocumentById: (endPoint, id_statement) => {
        const fetchConfig = { method: "GET" };

        // Fetch URL with parameters
        const fetchURL = endPoint + `?id_statement=${id_statement}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL, fetchConfig)
                .then((res) => {
                    return res.url
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Start a process to request information from Xero to build
    /// Insert data when change status to "Archivados" or "Recibidos":
    /// @param {id} id_invoice_xero - idXero
    setDataVoidWidthHoldings: async (WithholdingsArr) => {
        let arrayWithholding = { arrayWithholding: WithholdingsArr };

        return await fetch("/voidWithholding", {
            method: "POST",
            body: JSON.stringify(arrayWithholding),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'X-Mashape-Key': 'API_KEY',
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
            return false;
        });
    },

    /// Start a process to request information from Xero to build
    /// Insert data when change status to "Anulados"
    /// @param {WithholdingsArr} id_invoice_xero - idXero
    setDataReissueWidthHoldings: async (_Id) => {

        var param = { withholdingId: _Id };
        return await fetch("/reissueWithholding", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
            return false;
        });
    },

    /// Start a process to send information to Xero to
    /// change vouchers from pending to received status
    /// @param {string} taxType - tax type from the voucher
    /// @param {array} rowsSelected - rows selected by user in vouchers grid
    setDataWidthHoldings: async (taxType, rowsSelected) => {

        // Knowing endpoint to fetch for an approval based on tax type
        let fetchEndpoint = `/approveWithholding${taxType}`;
        let arrayWithholding = { arrayWithholding: rowsSelected };

        // Knowing endpoint to fetch for an approval based on tax type
        return await fetch(fetchEndpoint, {
            method: "POST",
            body: JSON.stringify(arrayWithholding),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'X-Mashape-Key': 'API_KEY',
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        });
    },

    // Petición para obtener cuentas bancarias de una empresa en Xero
    // @param {integer} id_organisation - organisation id
    getBankAccounts: (id_organisation) => {
        const fetchConfig = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        return fetch(
            `/getBankAccounts?id_organisation=${id_organisation}`,
            fetchConfig
        )
            .then((res) => res.json())
            .then((data) => {
                return {
                    data: data,
                };
            })
            .catch((err) => {
                console.log(err);
            });
    },

    convertBankStatement: async (fetchEndpoint, data) => {
        return await fetch(fetchEndpoint, {
            method: "POST",
            body: data,
        })
            .then((res) => res.json())
            .then((data) => {
                return {
                    data: data,
                };
            })
            .catch((err) => {
                console.log(err);
            });
    },

    uploadAdminFiles: async (fetchEndpoint, data) => {
        return await fetch(fetchEndpoint, {
            method: "POST",
            body: data,
        })
            .then((res) => res.json())
            .then((data) => {
                return {
                    data: data,
                };
            })
            .catch((err) => {
                console.log(err);
            });
    },

    // Petición cargar archivo en una ruta especifica 
    // @param {FormData} File - Archivo a cargar
    uploadFile: async (data, endPoint) => {

        return await fetch(endPoint, {
            method: "POST",
            body: data,
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    getConversions: (id_organisation, id_bank_xero) => {
        const fetchConfig = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        return fetch(
            `/getConversions?id_organisation=${id_organisation}&id_bank_xero=${id_bank_xero}`,
            fetchConfig)
            .then((res) => res.json())
            .then((data) => {
                return {
                    data: data,
                };
            })
            .catch((err) => {
                console.log(err);
            });
    },

    /// Petición para obtener el libro de Compras y ventas en Xero
    /// deppendiendo del periodo
    /// @param {text} id_organisation - organisation id
    /// @param {text} initialDate - Format date DD/MM/YYYY"
    /// @param {text} endDate -  Format date DD/MM/YYYY"
    /// @param {text} endPoint - Ruta de acceso al Endpoint dependiendo si es compras o venntas
    getBook: async (id_organisation, Periodo, initialDate, endDate, endPoint) => {

        // Fetch URL with parameters
        const fetchURL = endPoint + `?id_organisation=${id_organisation}&initialDate=${initialDate}&endDate=${endDate}`;

        return await fetch(fetchURL)
            .then((res) => res.text())
            .then((data) => {
                return {
                    data: data,
                };
            })
            .catch((error) => {
                console.log(error);
                return false;
            })
    },

    /// Petición para obtener los libros fiscales generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de libro fiscal 
    /// @param {string} endDate - Fecha de fin de libro fiscal
    getFiscalBooks: (organizationId, startDate, endDate) => {

        // Fetch URL with parameters
        const fetchURL = `/getTaxbooks?id_organisation=${organizationId}&init_date=${startDate}&end_date=${endDate}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para actualizar los libros fiscales de Compras generados desde Xero
    /// @param {string} id_taxbook - ID de reporte
    getFiscalBooksCompras: (rowID) => {
        // Fetch URL with parameters
        const fetchURL = `/purchasesBook?id_taxbook=${rowID}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para actualizar los libros fiscales de Ventas generados desde Xero
    /// @param {string} id_taxbook - ID de reporte
    getFiscalBooksVentas: (rowID) => {
        // Fetch URL with parameters
        const fetchURL = `/salesBook?id_taxbook=${rowID}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener el listado de reportes financieros generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de libro fiscal 
    /// @param {string} endDate - Fecha de fin de libro fiscal
    getReportsFinan: (organizationId, type) => {

        // Fetch URL with parameters
        const fetchURL = `/getReports?id_organisation=${organizationId}&type=${type}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener el balance general generado desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} endDate - Fecha de fin de el balance general
    getbalanceSheetReport: (organizationId, endDate) => {

        // Fetch URL with parameters
        const fetchURL = `/balanceSheetReport?id_organisation=${organizationId}&endDate=${endDate}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener los impuestos de IVA
    generateSupplierIVAReport: (organizationId, startDate, endDate, contactID) => {
        const fetchConfig = { method: "GET" };
        // Fetch URL with parameters
        const fetchURL = `/generateSupplierIVAReport?id_organisation=${organizationId}&initialDate=${startDate}&endDate=${endDate}&contact_id=${contactID}`;
        // Fetching data from the endpoint
        return (
            // Fetching data from the endpoint
            fetch(fetchURL, fetchConfig)
                .then((res) => {
                    return res.url
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener los impuestos de IVA
    generateSupplierISLRReport: (organizationId, startDate, endDate, contactID) => {
        const fetchConfig = { method: "GET" };
        // Fetch URL with parameters
        const fetchURL = `/generateSupplierISLRReport?id_organisation=${organizationId}&initialDate=${startDate}&endDate=${endDate}&contact_id=${contactID}`;
        // Fetching data from the endpoint
        return (
            // Fetching data from the endpoint
            fetch(fetchURL, fetchConfig)
                .then((res) => {
                    return res.url
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener el estado de resultados generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    getprofitAndLossReport: (organizationId, startDate, endDate) => {
        // Fetch URL with parameters
        const fetchURL = `/profitAndLossReport?id_organisation=${organizationId}&initialDate=${startDate}&endDate=${endDate}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener el libro diario generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    generalLedgerSummaryReport: (data) => {
        // Fetch URL with parameters
        const fetchURL = `/generalLedgerSummaryReport`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL, {
                method: "POST",
                body: data
            })
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener el libro mayor generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    generalLedgerReport: (data) => {

        const fetchURL = `/generalLedgerReport`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL, {
                method: "POST",
                body: data
            })
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener los proveedores
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    returnProvee: (organizationId) => {
        // Fetch URL with parameters
        const fetchURL = `/getAllSuppliers?id_organisation=${organizationId}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener los proveedores
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    returnProveeImp: (organizationId) => {
        // Fetch URL with parameters
        const fetchURL = `/getSuppliersAndEmployees?id_organisation=${organizationId}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener las cuentas 
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    returnCuentas: (organizationId) => {
        // Fetch URL with parameters
        const fetchURL = `/getAccounts?id_organisation=${organizationId}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener Certificado Retención en la Fuente generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    /// @param {string} contactID - ID del proveedor
    RetFuenteReport: (organizationId, startDate, endDate, contactID) => {
        // Fetch URL with parameters
        const fetchURL = `/retFuenteReport`;
        var param = {
            id_organisation: organizationId,
            initialDate: startDate,
            endDate: endDate,
            contactID: contactID,
        };
        return (
            // Fetching data from the endpoint
            fetch(fetchURL, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener Certificado Retención de Industria y Comercio generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    /// @param {string} contactID - ID del proveedor
    RetICAReport: (organizationId, startDate, endDate, contactID) => {
        // Fetch URL with parameters
        const fetchURL = `/retICAReport`;
        var param = {
            id_organisation: organizationId,
            initialDate: startDate,
            endDate: endDate,
            contactID: contactID,
        };
        return (
            // Fetching data from the endpoint
            fetch(fetchURL, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener el Retención en la Fuente generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    impRetFuenteReport: (organizationId, startDate, endDate) => {
        // Fetch URL with parameters
        const fetchURL = `/impRetFuenteReport`;
        var param = {
            id_organisation: organizationId,
            initialDate: startDate,
            endDate: endDate,
        };
        return (
            // Fetching data from the endpoint
            fetch(fetchURL, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener el Impuestoas a las Ventas por Pagar generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    RetIVAReport: (organizationId, startDate, endDate) => {
        // Fetch URL with parameters
        const fetchURL = `/impRetIVAReport`;
        var param = {
            id_organisation: organizationId,
            initialDate: startDate,
            endDate: endDate,
        };
        return (
            // Fetching data from the endpoint
            fetch(fetchURL, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener el Balance de prueba generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    balPruebaReport: (organizationId, startDate, endDate) => {
        // Fetch URL with parameters
        const fetchURL = `/testBalanceReport`;
        var param = {
            id_organisation: organizationId,
            initialDate: startDate,
            endDate: endDate,
        };
        return (
            // Fetching data from the endpoint
            fetch(fetchURL, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener el Balance de prueba filtrados generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    balPruebaReportFiltered: (organizationId, startDate, endDate, contactID, accountID) => {
        // Fetch URL with parameters
        const fetchURL = `/filteredTestBalanceReport`;
        var param = {
            id_organisation: organizationId,
            initialDate: startDate,
            endDate: endDate,
            id_account: accountID,
            id_contact: contactID
        };
        return (
            // Fetching data from the endpoint
            fetch(fetchURL, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para obtener Retención de Industria y Comercio generados desde Xero
    /// @param {string} organizationId - ID de la organización seleccionada en el menú
    /// @param {string} startDate - Fecha de inicio de estado de resultados
    /// @param {string} endDate - Fecha de fin de estado de resultados
    impICAReport: (organizationId, startDate, endDate) => {
        // Fetch URL with parameters
        const fetchURL = `/impICAReport`;
        var param = {
            id_organisation: organizationId,
            initialDate: startDate,
            endDate: endDate,
        };
        return (
            // Fetching data from the endpoint
            fetch(fetchURL, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    //Petición que regresa los grupos a los que tengo acceso en xero
    getGroupsList: async () => {

        // Get groups from logged user
        return await fetch('/getPractices')
            .then(res => res.json())
            .then(dataGroup => {
                return dataGroup
            })
            .catch((error) => {
                console.log(error);
            });
    },

    /// Petición para obtener las organizaciones a las que tengo acceso
    /// @param {string} practice_id - id de la práctica seleccionada
    getOrganizations: async (practice_id) => {

        // Fetch URL with parameters
        const fetchURL = `/getOrganisationsByPractice?practice_id=${practice_id}`;

        return await fetch(fetchURL)
            .then((res) => res.json())
            .then((dataOrg) => {
                let organizations = dataOrg.map((res) => {
                    return {
                        type: "xeroOrgName",
                        name: res.xeroOrgName,
                        id: res.organisationId,
                        specialContrib: res.isSpecialContrib
                    };
                });
                return organizations;
            }).catch((error) => {
                console.log(error);
                return false;
            });
    },

    /// Petición para obtener las prácticass a las que tengo acceso
    getAllPractices: async () => {
        // Fetch URL with parameters
        const fetchURL = `/getAllPractices`;

        return await fetch(fetchURL)
            .then((res) => res.json())
            .then((dataOrg) => {
                let Practices = dataOrg.map((res) => {
                    return {
                        type: "xeroGroupName",
                        name: res.practiceName,
                        id: res.practiceID,
                    };
                });
                return Practices;
            }).catch((error) => {
                console.log(error);
                return false;
            });
    },

    /// Petición para obtener los datos de acceso asociados al usuario logueado
    getUserInfo: async () => {

        // Fetch URL with parameters
        const fetchURL = `/getUserInfo `;

        return await fetch(fetchURL)
            .then((res) => res.json())
            .then((res) => {
                return {
                    email: res.email,
                    name: res.name,
                };
            }).catch((error) => {
                console.log(error);
                return false;
            });
    },

    /// Petición para obtener el libro de Compras y ventas en Xero
    /// deppendiendo del periodo
    /// @param {text} id_organisation - organisation id
    /// @param {text} initialDate - Format date DD/MM/YYYY"
    /// @param {text} endDate -  Format date DD/MM/YYYY"
    /// @param {text} dueDate -  Format date DD/MM/YYYY"
    /// @param {text} endPoint - Ruta de acceso al Endpoint dependiendo si es compras o venntas
    getBookRetention: async (
        id_organisation,
        Periodo,
        initialDate,
        endDate,
        endPoint,
        dueDate
    ) => {
        switch (Periodo) {
            case "1":
                let Range = util.getmonthRange();
                initialDate = Range.firstDay;
                endDate = Range.lastDay;
                break;

            case "2":
                let PreviousRange = util.getPreviousRange();
                initialDate = PreviousRange.firstDay;
                endDate = PreviousRange.lastDay;
                break;

            default:
                break;
        }

        var param = {
            id_organisation: id_organisation,
            initialDate: initialDate,
            endDate: endDate,
            dueDate: dueDate,
        };

        return await fetch(endPoint, {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.text())
            .then((data) => {
                return { data: data };
            });
    },

    /// Petición para obtener cuentas bancarias de una empresa en Xero
    /// @param {text} taxbookId - id que regresa getSalesBook or getPurchaseBook
    /// @param {text} endPoint - Ruta de acceso al Endpoint dependiendo si es compras o venntas
    getDocumentByIdStatement: (valor, endPoint) => {
        let id_statement = valor;
        id_statement = id_statement.replace(/['"]+/g, "");

        // Fetch URL with parameters
        const fetchURL = endPoint + `?id_statement=${id_statement}`;

        return (
            // Fetching data from the endpoint
            fetch(fetchURL)
                .then((res) => res.json())
                .then((data) => {
                    return {
                        data: data,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Petición para integrar el AccesToken de Xero a kiiper
    /// @param {text} accesToken - accesToken proveniente de xero desde el bot
    getFinalCallback: async (accestoken) => {

        var array = JSON.parse(accestoken);

        var param = {
            access_token: array,
        };

        return await fetch("/finalCallback", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            return res.url
        }).catch((err) => {
            console.log(err);
        });;
    },

    /// Consultar lista de declaraciones
    /// @param {string} id_organisation - ID de la organización en la base de datos asociado a la petición
    // /getGrantedOrganisations
    /// @param {int} endPoint - Tipo de impuesto, donde 1 corresponde a Retenciones de IVA, y
    //  2 corresponde a Retenciones de ISLR
    /// @param {int} id_statement_status - Estatus de la declaración, donde 1 es Por generar, 2 es Por aprobar,
    //  3 es Aprobados, 4 es Declarados, 5 es Por pagar y 6 es Pagados
    getStatements: (id_organisation, id_tax_type, id_statement_status) => {
        const fetchConfig = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        return fetch(
            `/getStatements?id_organisation=${id_organisation}&id_tax_type=${id_tax_type}&id_statement_status=${id_statement_status}`,
            fetchConfig)
            .then((res) => res.json())
            .then((data) => {
                return {
                    data: data,
                };
            })
            .catch((err) => {
                console.log(err);
            });
    },

    /// Recalcular una declaración en status Por generar
    /// @param {string} id_statement - ID de la declaración asociado a la petición /getStatements
    updateStatement: async (id_statement) => {
        var param = {
            id_statement: id_statement,
        };

        return await fetch("/updateStatement", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// Rechazar una declaración (enviar una declaración desde el estatus Por aprobar a Por generar)
    /// @param {string} id_statements - el cual es un arreglo que contiene
    /// “id_statement” (ID de la declaración asociado a la petición /getStatements)
    denyStatement: async (data) => {
        var param = { arrayStatement: data };

        return await fetch("/denyStatement", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// Rechazar una declaración (enviar una declaración desde el estatus Por aprobar a Por generar)
    /// @param {string} id_statements - el cual es un arreglo que contiene
    /// “id_statement” (ID de la declaración asociado a la petición /getStatements)
    retryDeclareStatement: async (data) => {
        var param = { arrayStatement: data };

        return await fetch("/retryDeclareStatement", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// Registra la declaración
    /// @param {array} arrayStatement el cual es un arreglo que contiene
    registerStatement: async (data) => {

        var param = { arrayStatement: data };

        return await fetch("/registerStatement", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'X-Mashape-Key': 'API_KEY',
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// Generar el pago de la declaración
    /// @param {array} arrayStatement el cual es un arreglo que contiene
    payStatement: async (data) => {

        var param = { arrayStatement: data };

        return await fetch("/payStatement", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'X-Mashape-Key': 'API_KEY',
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// Generar una declaración (enviar una declaración desde el estatus Por generar a Por aprobar)
    /// @param {array} arrayStatement el cual es un arreglo que contiene
    generateStatement: async (data) => {
        var param = { arrayStatement: data };

        return await fetch("/generateStatement", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// Aprobar una declaración (enviar una declaración desde el estatus Por aprobar a Aprobados)
    /// @param {array} arrayStatement el cual es un arreglo que contiene
    /// “id_statement” (ID de la declaración asociado a la petición /getStatements)
    approveStatement: async (data) => {

        var param = { arrayStatement: data };

        return fetch("/approveStatement", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            timeout: 1200000
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// Aprobar una declaración por parte del cliente (enviar una declaración desde el estatus
    /// Por aprobar a Aprobados dependiendo de acción enviada por correo electrónico)
    /// “id_statement” (ID de la declaración asociado a la petición /getStatements)
    approveStatementClient: async (id_statement, approve) => {
        var param = {
            id_statement: id_statement,
            approve: approve,
        };

        return await fetch("/approveStatementClient", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            timeout: 1200000
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// Declarar un registro de declaración (enviar una declaración desde el estatus Aprobados a Declarados
    /// @param {array} arrayStatement el cual es un arreglo que contiene
    /// “id_statement” (ID de la declaración asociado a la petición /getStatements)
    declareStatement: async (data) => {

        var param = { arrayStatement: data };

        return await fetch("/declareStatement", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    //Función que desloguea al usuario actual y destruye la sesión
    logoutFunction: async () => {
        return await fetch("/logout")
            .then().catch((err) => {
                console.log(err);
            });
    },

    //Petición que regresa la url para el callback de xero
    getConsentUrl: async () => {

        // Get groups from logged user
        return await fetch('/getConsentUrl')
            .then(res => res.text())
            .then(data => {
                return data = data.substring(1, data.length - 1);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    getBankStatements: async (id_conversion) => {

        // Fetch URL with parameters
        const fetchURL = `/getBankStatementsByConversion?conversionId=${id_conversion}`;

        return (
            // Fetching data from the endpoint
            await fetch(fetchURL)
                .then((res) => {
                    return res.url
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    getBankStatementsByTransactions: async (companyName, data) => {

        const fetchURL = `/getBankStatementsByTransactions?companyName=${companyName}&transactions=${JSON.stringify(data)}`;
        return (
            // Fetching data from the endpoint
            await fetch(fetchURL)
                .then((res) => {
                    return res.url
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                })
        );
    },

    /// Declarar un registro de declaración (enviar una declaración desde el estatus Aprobados a Declarados
    /// @param {array} arrayStatement el cual es un arreglo que contiene
    /// “id_statement” (ID de la declaración asociado a la petición /getStatements)
    updateOrganisation: async (data) => {

        var param = {
            id_organisation: data[0].id_organisation,
            group: data[0].group,
            practice_id: data[0].practice_id,
            administrator_name: data[0].administrator_name,
            administrator_email: data[0].administrator_email,
            client_name: data[0].client_name,
            client_email: data[0].client_email,
            taxes: data[0].taxes,
            initial_date_taxes: data[0].initial_date_taxes,
            user_seniat: data[0].user_seniat,
            password_seniat: data[0].password_seniat,
            iva_correlative: data[0].iva_correlative,
            islr_correlative: data[0].islr_correlative,
            bank_statements: data[0].bank_statements,
            legal_reports: data[0].legal_reports,
            account_code: data[0].account_code
        };

        return await fetch("/updateOrganisation", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// función utilizada para agregar prácticas
    /// @param {array} arrayStatement el cual es un arreglo que contiene
    /// id_practice (Nombre de la práctica asociada)
    addPractice: async (practiceName) => {

        var param = { practiceName: practiceName };

        const fetchConfig = {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        };

        // Fetch URL with parameters
        const fetchURL = `/addPractice`;

        return await fetch(fetchURL, fetchConfig)

            .then((res) => {
                if (res.ok) {
                    return true;
                } else {
                    return false;
                }
            }).catch((err) => {
                console.log(err);
            });
    },

    //obtiene la informacion de la organización
    returnTaxStatus: async (id_organisation) => {
        const fetchConfig = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        return await fetch(`/getOrganisationInfo?id_organisation=${id_organisation}`, fetchConfig)
            .then(res => res.json())
            .then(data => {
                return {
                    data: data.taxes
                }
            }).catch(err => {
                console.log(err)
            })
    },

    /// Función que actializa la información
    /// @param {string} practice_id - ID de la práctica seleccionada
    updateDashboardPanelPractice: (practice_id) => {
        var param = { practice_id: practice_id };

        return fetch("/updateDashboardPanelPractice", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    /// Función que actializa la información
    /// @param {string} id_organisation - ID de la organización seleccionada
    updateDashboardPanel: (id_organisation) => {
        var param = { id_organisation: id_organisation };

        return fetch("/updateDashboardPanel", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    Contribuyenteespecial: async (id_organization) => {

        var isSpecialContrib = "";
        await fetch(`/getTaxInfoByOrganisation?id_organisation=${id_organization}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => res.json())
            .then(data => {
                isSpecialContrib = data.isSpecialContrib
            })
            .catch((error) => {
                console.log(error);
            });

        return isSpecialContrib;
    },

    getInitDateEstResult: async (id_organization) => {

        var InitDateEstResult = "";
        await fetch(`/getTaxInfoByOrganisation?id_organisation=${id_organization}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => res.json())
            .then(data => {
                InitDateEstResult = data.xeroFinancialYearEndDate
            })
            .catch((error) => {
                console.log(error);
            });

        return InitDateEstResult;
    },

    /// Petición para obtener las tasas de cambio en la tabla
    getTable: async (id_organisation) => {
        // Fetch URL with parameters
        const fetchURL = `/getCurrencyRates`;

        return await fetch(fetchURL)
            .then((res) => res.json())
            .then((res) => {
                return res;
            }).catch((error) => {
                console.log(error);
                return false;
            });
    },

    saveTasaCambio: async (fecha, monto) => {
        var param = { date: fecha, amount: monto };

        return fetch("/addCurrencyRate", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    },

    updateTasaCambio: async (id, fecha, monto) => {
        var param = { currencyRateId: id, date: fecha, amount: monto };

        return fetch("/updateCurrencyRate", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => {
            if (res.ok) {
                return true;
            } else {
                return false;
            }
        }).catch((err) => {
            console.log(err);
        });
    }
};

export default calls;