import React, { useEffect, useState } from "react";
import styles from "./sales.module.css";
import { Doughnut } from "react-chartjs-2";
import { Grid, Table, TableBandHeader, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import ChartDataLabels from "chartjs-plugin-datalabels";
import util from "../../../Js/util.js";
import GridUI from "@material-ui/core/Grid";
import Backdrop from "../../Backdrop";
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Total amount of clients table
let totalAmountClients = 0;
let totalOverdueInvoices = 0;
var currencyCliente = "";
var currencyInvoice = "";

const tableStyles = {
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: '#faf8fe',
        },
    },
    tableHeader: {
        border: 'none',
        backgroundColor: '#e4def9',
        fontSize: '15px',
        fontWeight: 600,
        color: '#131f3e',
        '&:first-child': {
            borderRadius: '10px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 10px 0 0',
        }
    },
    tableHeaderBand: {
        border: 'none',
        backgroundColor: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 600,
        color: '#4a22d4',
    },
    tableBody: {
        border: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#131f3e',
    },
};

const tableBandCellBase = ({ classes, ...props }) => (
    <TableBandHeader.Cell
        {...props}
        className={classes.tableHeaderBand}
    />
);

const tableHeaderCellBase = ({ classes, ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableComponentBase = ({ classes, ...props }) => (
    <Table.Table
        {...props}
        className={classes.tableStriped}
    />
);

const TableCellComponentBase = ({ classes, ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const tableContentComponent = props => (
    <TableHeaderRow.Content {...props} align={'center'} />
);

const tableBandCell = withStyles(tableStyles, { name: 'tableBandCell' })(tableBandCellBase);
const tableHeaderCell = withStyles(tableStyles, { name: 'tableHeaderCell' })(tableHeaderCellBase);
const TableComponent = withStyles(tableStyles, { name: 'TableComponent' })(TableComponentBase);
const TableCellComponent = withStyles(tableStyles, { name: 'TableCellComponent' })(TableCellComponentBase);

const Sales = (props) => {
    // Component state
    const [t] = useTranslation("global");
    // Number format for charts tooltip
    let numberFormat = new Intl.NumberFormat(t("language.currencyFormat"));
    const [showBackdrop, setshowBackdrop] = useState(false);
    const [overdueInvoice, setoverdueInvoice] = useState({
        rowData: [],
        charts: {
            plugins: [ChartDataLabels],
            data: {},
        },
    });

    const [mainClients, setmainClients] = useState({
        rowData: [],
        charts: {
            plugins: [ChartDataLabels],
            data: {},
        },
    });

    const chartOptionsInvoice = {
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => {
                    let label = ` ${data.labels[tooltipItem.index]}` || '';

                    if (label) {
                        label += ': ';
                    }

                    label += numberFormat.format(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2));

                    return label;
                }
            },
            backgroundColor: 'rgba(19, 31, 62, 0.80)',
        },
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: "#131f3e",
                font: {
                    family: "'Goldplay',sans-serif",
                    weight: "600",
                },
                formatter: function (value) {
                    const partPercentage = parseInt(
                        (value * 100) / totalOverdueInvoices
                    );
                    return `${partPercentage}%`;
                },
            },
        },
        legend: {
            display: true,
            labels: {
                fontColor: "#131f3e",
                fontFamily: "'Goldplay',sans-serif",
                boxWidth: 10,
                fontSize: 10,
                padding: 15
            },
            position: "bottom",
        },
    };

    const chartOptionsClients = {
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => {
                    let label = ` ${data.labels[tooltipItem.index]}` || '';

                    if (label) {
                        label += ': ';
                    }

                    label += numberFormat.format(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2));

                    return label;
                }
            },
            backgroundColor: 'rgba(19, 31, 62, 0.80)',
        },
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: "#131f3e",
                font: {
                    family: "'Goldplay',sans-serif",
                    weight: "600",
                },
                formatter: function (value) {
                    const partPercentage = parseInt(
                        (value * 100) / totalAmountClients
                    );
                    return `${partPercentage}%`;
                },
            },
        },
        legend: {
            display: true,
            labels: {
                fontColor: "#131f3e",
                fontFamily: "'Goldplay',sans-serif",
                boxWidth: 10,
                fontSize: 10,
                padding: 15
            },
            position: "bottom",
        },
    };

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    // Component Did Mount event
    useEffect(() => {
        (async () => {
            // This component presents information ONLY for Organization dashboard
            if (props.orgIdSelected) {
                // ----------------------------------------------------
                setshowBackdrop(true);
                /**  Requesting data first */

                // Setting overdue invoice data
                const invoiceDataResult = await fetch(
                    `/getOverdueInvoices?id_organisation=${props.orgIdSelected}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                ).then((res) => res.json()
                ).then((data) => {
                    return Promise.resolve(data);
                });

                // ----------------------------------------------------
                // Setting main clients data
                const clientDataResult = await fetch(
                    `/getClientsOwingYou?id_organisation=${props.orgIdSelected}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                ).then((res) => res.json()
                ).then((data) => {
                    return Promise.resolve(data);
                });

                // GridUI And Chart invoice
                var overdueInvoicesChartData = [];
                var overdueInvoicesChartLabels = [];

                currencyInvoice = invoiceDataResult.baseCurrency;
                var overdueInvoicesData = invoiceDataResult.arrayInvoices.map(
                    (item) => {
                        let label = "";
                        switch (item.MonthsDue) {
                            case "Vigentes":
                                label = t("dashboardGroup.current");
                                break;
                            case "1 mes":
                                label = "1 " + t("dashboardGroup.month");
                                break;
                            case "3 meses":
                                label = "3 " + t("dashboardGroup.months");
                                break;
                            case "+3 meses":
                                label = "+3 " + t("dashboardGroup.months");
                                break;
                            default:
                                break;
                        }
                        return {
                            baseCurrencyInvoiceVef: invoiceDataResult.baseCurrency,
                            behind: label,
                            [invoiceDataResult.baseCurrency]: parseFloat(item.AmountDue).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                            AmountDue: item.AmountDue
                        };
                    }
                );

                totalOverdueInvoices = overdueInvoicesData.reduce(
                    (total, invoice) => {
                        return total + invoice.AmountDue;
                    },
                    0
                );

                overdueInvoicesChartData = overdueInvoicesData.map(
                    (invoice) => {
                        return invoice.AmountDue;
                    }
                );

                overdueInvoicesChartLabels = overdueInvoicesData.map(
                    (invoice) => {
                        return invoice.behind;
                    }
                );

                // ----------------------------------------------------
                // GridUI And Chart clients
                var clientsChartData = [];
                var clientsChartLabels = [];
                currencyCliente = invoiceDataResult.baseCurrency;

                var mainClientsData = clientDataResult.arrayInvoices.map(
                    (item) => {
                        return {
                            baseCurrencyVef: clientDataResult.baseCurrency,
                            behind: item.ContactName,
                            [clientDataResult.baseCurrency]: parseFloat(item.AmountDue).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                            AmountDue: item.AmountDue
                        };
                    });

                // Getting total amount from all clients
                totalAmountClients = mainClientsData.reduce(
                    (total, client) => {
                        return total + client.AmountDue
                    }, 0);

                // Setting values for clients chart
                clientsChartData = mainClientsData.map(
                    (client) => {
                        return client.AmountDue;
                    });

                // Setting labels for clients chart
                clientsChartLabels = mainClientsData.map(
                    (client) => {
                        return client.behind.substring(0, 10) + "..";
                    });

                // Updating overdueInvoice state
                setoverdueInvoice(prevoverdueInvoice => ({
                    ...prevoverdueInvoice,
                    rowData: overdueInvoicesData,
                    charts: {
                        data: {
                            datasets: [
                                {
                                    data: overdueInvoicesChartData,
                                    backgroundColor: [
                                        "#fedbb6",
                                        "#f7b5d2",
                                        "#8ad3df",
                                        "#9ed4b0",
                                        "rgba(74, 34, 212, 0.6)",
                                    ],
                                },
                            ],
                            labels: overdueInvoicesChartLabels,
                        },
                    },
                }));

                // Updating mainClients state
                setmainClients(prevmainClients => ({
                    ...prevmainClients,
                    rowData: mainClientsData,
                    charts: {
                        data: {
                            datasets: [
                                {
                                    data: clientsChartData,
                                    backgroundColor: [
                                        "#fedbb6",
                                        "#f7b5d2",
                                        "#8ad3df",
                                        "#9ed4b0",
                                        "rgba(74, 34, 212, 0.6)",
                                    ],
                                },
                            ],
                            labels: clientsChartLabels,
                        },
                    },
                }));
                setshowBackdrop(false);
            }
        })();
    }, [props.orgIdSelected, t]);

    // ----------------------------------------------------
    return (
        <>
            <GridUI
                container
                direction="row"
            >
                <GridUI item xs={12} lg={3}>
                    <Grid
                        rows={overdueInvoice.rowData}
                        columns={util.returnHeaderInvoice(currencyInvoice, t("dashboardGroup.antiquity"))}
                    >
                        <Table
                            tableComponent={TableComponent}
                            messages={tableMessages}
                            cellComponent={TableCellComponent}
                            columnExtensions={util.returnHeaderExtension(currencyInvoice)}
                        />
                        <TableHeaderRow
                            cellComponent={tableHeaderCell}
                            contentComponent={tableContentComponent}
                        />
                        <TableBandHeader
                            cellComponent={tableBandCell}
                            columnBands={util.returnHeaderInvoiceBand(currencyInvoice, t("dashboardGroup.invoices"))}
                        />
                    </Grid>
                </GridUI>
                <GridUI item xs={12} lg={3} className={styles.SalesChart}>
                    <div className={styles.ChartTitle}>{t("dashboardGroup.antiquity")}</div>
                    <div className={styles.ChartContainer}>
                        {totalAmountClients === 0 ?
                            <div style={{ textAlign: "center", zIndex: "9999", fontSize: "9pt" }} >{t("miscellaneous.noData")}</div>
                            :
                            null
                        }
                        <Doughnut
                            data={overdueInvoice.charts.data}
                            options={chartOptionsInvoice}
                        />
                    </div>
                </GridUI>
                {props.dashboardType !== "group" ? (
                    <GridUI item xs={12} lg={6}>
                        <GridUI
                            container
                            direction="row"
                        >
                            <GridUI item xs={12} lg={6}>
                                <Grid
                                    rows={mainClients.rowData}
                                    columns={util.returnHeaderInvoice(currencyCliente, t("dashboardGroup.client"))}
                                >
                                    <Table
                                        tableComponent={TableComponent}
                                        messages={tableMessages}
                                        cellComponent={TableCellComponent}
                                        columnExtensions={util.returnHeaderExtension(currencyCliente)}
                                    />
                                    <TableHeaderRow
                                        cellComponent={tableHeaderCell}
                                        contentComponent={tableContentComponent}
                                    />
                                    <TableBandHeader
                                        cellComponent={tableBandCell}
                                        columnBands={util.returnHeaderInvoiceBand(currencyCliente, t("dashboardGroup.mainClients"))}
                                    />
                                </Grid>
                            </GridUI>
                            <GridUI item xs={12} lg={6} className={styles.SalesChart}>
                                <div className={styles.ChartTitle}>
                                    {t("dashboardGroup.mainClients")}
                                </div>
                                <div className={styles.ChartContainer}>
                                    {totalOverdueInvoices === 0 ?
                                        <div style={{ textAlign: "center", zIndex: "9999", fontSize: "9pt" }} >{t("miscellaneous.noData")}</div>
                                        :
                                        null
                                    }
                                    <Doughnut
                                        data={mainClients.charts.data}
                                        options={chartOptionsClients}
                                    />
                                </div>
                            </GridUI>
                        </GridUI>
                    </GridUI>
                ) : null}
            </GridUI>
            <Backdrop
                open={showBackdrop}
            />
        </>
    );
}

export default Sales;
