import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducer = combineReducers({
    todos: (state = {}, action) => {
        switch (action.type) {
            case 'addToken':
                return { ...state, token: action.item };
            case 'addGroup':
                return { ...state, grp: action.item };
            case 'addAuth':
                return { ...state, auth: action.item };
            case 'addUserInfo':
                return { ...state, userInfo: action.item };
            case 'addOrgName':
                return { ...state, orgName: action.item };
            case 'addOrgID':
                return { ...state, orgID: action.item };
            case 'addPracticeName':
                return { ...state, practiceName: action.item };
            case 'addPracticeID':
                return { ...state, practiceID: action.item };
            case 'addBankAcc':
                return { ...state, dashboardBankAccounts: action.item };
            case 'addEvents':
                return { ...state, event: action.item };
            case 'addOrganisations':
                return { ...state, organisations: action.item };
            case 'addDropdownValue':
                return { ...state, ddlval: action.item };
            case 'addTaxes':
                return { ...state, taxes: action.item };
            case 'addSelected':
                return { ...state, selected: action.item };
            case 'clearInfo':
                return state = null;
            default:
                return state
        }
    }
})

const persistConfig = { // configuration object for redux-persist
    key: 'root',
    storage, // define which storage to use
}

const persistedReducer = persistReducer(persistConfig, reducer)

let store = createStore(persistedReducer);

let persistor = persistStore(store);

export { store, persistor };