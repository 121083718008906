/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

/** Components ----------------------------------------------------------- */
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';

/** Component styles */
const useStyles = makeStyles((theme) => ({
    error: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 100,
            marginBottom: 20,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 200,
            marginBottom: 20,
        },
    },
    ooops: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
            marginBottom: 20,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 50,
            marginBottom: 20,
        },
    },
    notFound: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            fontWeight: 400,
            color: '#FFF',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 24,
            fontWeight: 400,
            color: '#FFF',
        },
    },
    marginBox: {
        marginTop: '10vh',
    },
    btnRoot: {
        borderRadius: 10,
        marginTop: 64,
        '&:hover': {
            backgroundColor: '#6e4edd',
        },
    },
    goBackLink: {
        textDecoration: 'none',
    },
}));

const Error = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    return (
        <Grid container justifyContent="center" alignItems="flex-start" className="error-body">
            <Grid item xs={12} md={12} lg={12}>
                <Box align="center" className={classes.marginBox}>
                    <Typography variant="h1" color="secondary" className={classes.error}> {t("error.code")} </Typography>
                    <Typography variant="h2" color="secondary" className={classes.ooops}> {t("error.ooops")} </Typography>
                    <Typography className={classes.notFound}> {t("error.notFound")} </Typography>
                    <Link to="/home" className={classes.goBackLink}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            classes={{root: classes.btnRoot}}
                            disableElevation
                        >
                            {t('miscellaneous.goBack')}
                        </Button>
                    </Link>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Error;