import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

/// controles
import { Card, CardHeader, CardContent, Typography, Container } from '@material-ui/core';
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import GridUI from "@material-ui/core/Grid";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Grid, Table, TableBandHeader, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import SyncIcon from '@material-ui/icons/Sync';
import Backdrop from "./internos/Backdrop";
import SnackBar from "./internos/SnackBar";
import util from "./Js/util.js";
import calls from "./Js/calls";

/// CSS
import stylesS from "../Componentes/internos/Dashboard/Sales/sales.module.css";
import stylesP from "../Componentes/internos/Dashboard/Purchases/purchases.module.css";
import "../Css/DashboardGroup.css";
import "../Componentes/internos/Dashboard/dashboard.css";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
}))(Tooltip);

const tableStyles = {
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: '#faf8fe',
        },
    },
    tableHeader: {
        border: 'none',
        backgroundColor: '#e4def9',
        fontSize: '15px',
        fontWeight: 600,
        color: '#131f3e',
        '&:first-child': {
            borderRadius: '10px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 10px 0 0',
        }
    },
    tableHeaderBand: {
        border: 'none',
        backgroundColor: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 600,
        color: '#4a22d4',
    },
    tableBody: {
        border: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#131f3e',
    },
    toolbarSearch: {
        border: 'none',
        padding: '15px !important',
    },
    searchInput: {
        color: '#4a22d4',
        '&:before': {
            borderBottomColor: '#4a22d4',
        },
        '&:hover:before': {
            borderBottomColor: '#4a22d4 !important',
        }
    },
};

const useStyles = makeStyles({
    paper: {
        borderRadius: '10px',
        boxShadow: '10px 10px 5px 0px rgba(74,34,212,0.1)'
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: '5px'
        },
        padding: '10px'
    },
    cardContentRoot2: {
        '&:last-child': {
            paddingBottom: '23px'
        },
        padding: '10px'
    },
    cardHeaderRoot: {
        '& h2': {
            fontSize: '16px',
            fontWeight: '600'
        },
        backgroundColor: '#131f3e',
        height: '40px',
        color: '#00ffff',
    },
});

// Total amount of providers table
let totalOverdueBills = 0;
let totalOverdueInvoices = 0;
var currencyClienteCount = 0;
var currencyCliente = [];
var currencyInvoiceCount = 0;
var currencyInvoice = [];
var overdueInvoicesData = [];
var overdueBillsData = [];

const tableBandCellBase = ({ classes, ...props }) => (
    <TableBandHeader.Cell
        {...props}
        className={classes.tableHeaderBand}
    />
);

const tableHeaderCellBase = ({ classes, ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableComponentBase = ({ classes, ...props }) => (
    <Table.Table
        {...props}
        className={classes.tableStriped}
    />
);

const TableCellComponentBase = ({ classes, ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const tableContentComponent = props => (
    <TableHeaderRow.Content {...props} align={'center'} />
);

const tableBandCell = withStyles(tableStyles, { name: 'tableBandCell' })(tableBandCellBase);
const tableHeaderCell = withStyles(tableStyles, { name: 'tableHeaderCell' })(tableHeaderCellBase);
const TableComponent = withStyles(tableStyles, { name: 'TableComponent' })(TableComponentBase);
const TableCellComponent = withStyles(tableStyles, { name: 'TableCellComponent' })(TableCellComponentBase);

const DashboardGroup = () => {
    const classes = useStyles();

    const [t] = useTranslation("global");
    const todos = useSelector(state => state.todos);
    // Number format for charts tooltip
    const numberFormat = new Intl.NumberFormat(t("language.currencyFormat"));

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    const [showBackdrop, setBackDrop] = useState(false);
    const [show, setShow] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [texto, setTexto] = useState("");

    const chartOptionsBills = {
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => {
                    let label = ` ${data.labels[tooltipItem.index]}` || '';

                    if (label) {
                        label += ': ';
                    }

                    label += numberFormat.format(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2));

                    return label;
                }
            },
            backgroundColor: 'rgba(19, 31, 62, 0.80)',
        },
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: "#131f3e",
                font: {
                    family: "'Goldplay',sans-serif",
                    weight: "600",
                },
                formatter: (value) => {
                    const partPercentage = parseInt(
                        (value * 100) / totalOverdueBills
                    );
                    return `${partPercentage}%`;
                },
            },
        },
        legend: {
            display: true,
            labels: {
                fontColor: "#131f3e",
                fontFamily: "'Goldplay',sans-serif",
                boxWidth: 10,
                fontSize: 10,
                padding: 15
            },
            position: "bottom",
        }
    };

    const chartOptionsInvoice = {
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => {
                    let label = ` ${data.labels[tooltipItem.index]}` || '';
                    if (label) {
                        label += ': ';
                    }
                    label += numberFormat.format(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toFixed(2));
                    return label;
                }
            },
            backgroundColor: 'rgba(19, 31, 62, 0.80)',
        },
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: "#131f3e",
                font: {
                    family: "'Goldplay',sans-serif",
                    weight: "600",
                },
                formatter: (value) => {
                    const partPercentage = parseInt(
                        (value * 100) / totalOverdueInvoices
                    );
                    return `${partPercentage}%`;
                },
            },
        },
        legend: {
            display: true,
            labels: {
                fontColor: "#131f3e",
                fontFamily: "'Goldplay',sans-serif",
                boxWidth: 10,
                fontSize: 10,
                padding: 15
            },
            position: "bottom",
        },
    }

    const [overdueBill, setOverdueBill] = useState({
        rowData: [],
        charts: {
            plugins: [ChartDataLabels],
            data: {},
        },
    });

    const [overdueInvoice, setOverdueInvoice] = useState({
        rowData: [],
        charts: {
            plugins: [ChartDataLabels],
            data: {}
        },
    });

    /* Component events */
    useEffect(() => {
        (async () => {
            // Showing loading
            setBackDrop(true);

            /**  Requesting data first */
            // Setting overdue bill data
            const billDataResult = await fetch(
                `/getOverdueBillsPractice?id_practice=${todos.practiceID}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            ).then((res) => res.json()
            ).then((data) => {
                return Promise.resolve(data);
            });

            // Setting overdue invoice data
            const invoiceDataResult = await fetch(
                `/getOverdueInvoicesPractice?id_practice=${todos.practiceID}`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            ).then((res) => res.json()
            ).then((data) => {
                return Promise.resolve(data);
            });

            // ----------------------------------------------------
            //Grid Principal

            //Función utilizada para contar las monedas
            currencyInvoiceCount = 0;
            invoiceDataResult.forEach((item0) => {
                currencyInvoiceCount = item0.AmountDue.length;
            });

            //Función utilizada para obtener las monedas
            currencyInvoice = [];
            for (let index = 0; index < currencyInvoiceCount; index++) {
                if (index > currencyInvoiceCount)
                    return;
                let Invoice = {};
                Invoice.Currency = invoiceDataResult[index].AmountDue[index].Currency;
                currencyInvoice.push(Invoice);
            }

            overdueInvoicesData = [];
            invoiceDataResult.forEach((vallue, id) => {
                let element = {}
                for (let index = 0; index < invoiceDataResult[id].AmountDue.length; index++) {
                    let label = "";
                    switch (vallue.MonthsDue) {
                        case "Vigentes":
                            label = t("dashboardGroup.current");
                            break;
                        case "1 mes":
                            label = "1 " + t("dashboardGroup.month");
                            break;
                        case "3 meses":
                            label = "3 " + t("dashboardGroup.months");
                            break;
                        case "+3 meses":
                            label = "+3 " + t("dashboardGroup.months");
                            break;
                        default:
                            break;
                    }
                    element.ID = vallue.MonthsDue;
                    element.behind = label;
                    element.Currency = invoiceDataResult[id].AmountDue[index].Currency;
                    element[invoiceDataResult[id].AmountDue[index].Currency] = parseFloat(invoiceDataResult[id].AmountDue[index].Amount).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    element.AmountDue = invoiceDataResult[id].AmountDue[index].Amount;
                    element.MonthsDue = vallue.MonthsDue;
                }
                overdueInvoicesData.push(element);
            });

            // ----------------------------------------------------
            // Gráfico de ventas

            var overdueInvoicesChartData = [];
            var overdueInvoicesChartLabels = [];

            if (currencyInvoice.length === 1) {
                // Getting total amount from all invoices
                totalOverdueInvoices = overdueInvoicesData.reduce(
                    (total, invoice) => {
                        return total + invoice.AmountDue;
                    },
                    0
                );

                overdueInvoicesChartData = overdueInvoicesData.map(
                    (invoice) => {
                        return invoice.AmountDue;
                    }
                );

                overdueInvoicesChartLabels = overdueInvoicesData.map(
                    (invoice) => {
                        return invoice.behind;
                    }
                );
            }

            // ----------------------------------------------------
            // Grid de Compras

            currencyClienteCount = 0;
            billDataResult.forEach((item0) => {
                currencyClienteCount = item0.AmountDue.length;
            });

            currencyCliente = [];
            for (let index = 0; index < currencyClienteCount; index++) {
                if (index > currencyClienteCount)
                    return;
                let Client = {};
                Client.Currency = billDataResult[index].AmountDue[index].Currency;
                currencyCliente.push(Client);
            }

            overdueBillsData = [];
            billDataResult.forEach((vallue, id) => {
                let element = {}
                for (let index = 0; index < billDataResult[id].AmountDue.length; index++) {
                    let label = "";
                    switch (vallue.MonthsDue) {
                        case "Vigentes":
                            label = t("dashboardGroup.current");
                            break;
                        case "1 mes":
                            label = "1 " + t("dashboardGroup.month");
                            break;
                        case "3 meses":
                            label = "3 " + t("dashboardGroup.months");
                            break;
                        case "+3 meses":
                            label = "+3 " + t("dashboardGroup.months");
                            break;
                        default:
                            break;
                    }
                    element.ID = vallue.MonthsDue;
                    element.behind = label;
                    element.Currency = billDataResult[id].AmountDue[index].Currency;
                    element[billDataResult[id].AmountDue[index].Currency] = parseFloat(billDataResult[id].AmountDue[index].Amount).toLocaleString(t("language.currencyFormat"), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    element.AmountDue = billDataResult[id].AmountDue[index].Amount;
                    element.MonthsDue = vallue.MonthsDue;
                }
                overdueBillsData.push(element);
            });

            // ----------------------------------------------------

            /**  Calculating charts */

            // ----------------------------------------------------
            // Gráfico de compras
            // Slicing array to get USD data separated and converted to USD or Bolivars

            var overdueBillsChartData = [];
            var overdueBillsChartLabels = [];
            if (currencyCliente.length === 1) {

                // Getting total amount from all overdue bill
                totalOverdueBills = overdueBillsData.reduce(
                    (total, bill) => {
                        return total + bill.AmountDue;
                    },
                    0
                );

                // Setting values for overdue bill chart
                overdueBillsChartData = overdueBillsData.map(
                    (bill) => {
                        return bill.AmountDue;
                    }
                );

                // Setting labels for overdue bill chart
                overdueBillsChartLabels = overdueBillsData.map(
                    (bill) => {
                        return bill.behind;
                    }
                );
            }

            setOverdueBill(prevOverdueBill => ({
                ...prevOverdueBill,
                rowData: overdueBillsData,
                charts: {
                    data: {
                        datasets: [
                            {
                                data: overdueBillsChartData,
                                backgroundColor: [
                                    "#fedbb6",
                                    "#f7b5d2",
                                    "#8ad3df",
                                    "#9ed4b0",
                                    "rgba(74, 34, 212, 0.7)",
                                ],
                            },
                        ],
                        labels: overdueBillsChartLabels,
                    }
                },

            }));

            setOverdueInvoice(prevOverdueInvoice => ({
                ...prevOverdueInvoice,
                rowData: overdueInvoicesData,
                charts: {
                    data: {
                        datasets: [
                            {
                                data: overdueInvoicesChartData,
                                backgroundColor: [
                                    "#fedbb6",
                                    "#f7b5d2",
                                    "#8ad3df",
                                    "#9ed4b0",
                                    "rgba(74, 34, 212, 0.7)",
                                ],
                            },
                        ],
                        labels: overdueInvoicesChartLabels,
                    }
                },
            }));

            setBackDrop(false);
        })();
    }, [todos.practiceID, t]);

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const onClickUpdateGroupPanel = async () => {
        setBackDrop(true);
        await calls.updateDashboardPanelPractice(todos.practiceID)
            .then(() => {
                setShow(true);
                setTexto(t("principal.update"));
                setAlertType("info");
            });
        setBackDrop(false);
    }

    return (
        <Container maxWidth="lg" className="mainPanel">
            <GridUI container spacing={2} justifyContent="center" alignItems="center">
                <GridUI item xs className="botBreadcrumb">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Typography variant="h1" color="textSecondary">{todos.ddlval}</Typography>
                    </Breadcrumbs>
                </GridUI>
                <GridUI item className="botBreadcrumb">
                    <LightTooltip title={t("principal.updateGrp")} aria-label="update" placement="left">
                        <IconButton size="small" onClick={() => onClickUpdateGroupPanel()}>
                            <SyncIcon id="updateIcon" style={{ color: "#131f3e" }} fontSize="large" />
                        </IconButton>
                    </LightTooltip>
                </GridUI>
                <GridUI item xs={12}>
                    <GridUI
                        container
                        direction="row"
                        spacing={2}
                    >
                        <GridUI item xs={12} lg={6}>
                            <Card className={classes.paper}>
                                <CardHeader
                                    title={<Typography variant="h2">{t("principal.invoices")}</Typography>}
                                    classes={{ root: classes.cardHeaderRoot }}
                                />
                                <CardContent classes={{ root: classes.cardContentRoot }}>
                                    <GridUI
                                        container
                                        direction="row"
                                    >
                                        <GridUI item xs={12} lg>
                                            <Grid
                                                rows={overdueInvoice.rowData}
                                                columns={util.returnHeaderInvoiceGroup(currencyInvoice, t("dashboardGroup.antiquity"))}
                                            >
                                                <Table
                                                    tableComponent={TableComponent}
                                                    messages={tableMessages}
                                                    cellComponent={TableCellComponent}
                                                    columnExtensions={util.returnExtensionGroup(currencyInvoice)}
                                                />
                                                <TableHeaderRow
                                                    cellComponent={tableHeaderCell}
                                                    contentComponent={tableContentComponent}
                                                />
                                                <TableBandHeader
                                                    cellComponent={tableBandCell}
                                                    columnBands={util.returnHeaderInvoiceGroupBand(currencyInvoice, t("dashboardGroup.invoices"))}
                                                />
                                            </Grid>
                                        </GridUI>
                                        {currencyInvoiceCount <= 1 ?
                                            <GridUI item xs={12} lg className={stylesS.SalesChart}>
                                                <div className={stylesS.ChartContainer}>
                                                    {totalOverdueInvoices === 0 ?
                                                        <div style={{ textAlign: "center", zIndex: "9999", fontSize: "9pt" }} >{t("miscellaneous.noData")}</div>
                                                        :
                                                        null
                                                    }
                                                    <Doughnut
                                                        data={overdueInvoice.charts.data}
                                                        options={chartOptionsInvoice}
                                                    />
                                                </div>
                                            </GridUI>
                                            : null}
                                    </GridUI>
                                </CardContent>
                            </Card>
                        </GridUI>
                        <GridUI item xs={12} lg={6}>
                            <Card className={classes.paper}>
                                <CardHeader
                                    title={<Typography variant="h2">{t("principal.bills")}</Typography>}
                                    classes={{ root: classes.cardHeaderRoot }}
                                />
                                <CardContent classes={{ root: classes.cardContentRoot }}>
                                    <GridUI container direction="row" >
                                        <GridUI item xs={12} lg>
                                            <Grid
                                                rows={overdueBill.rowData}
                                                columns={util.returnHeaderInvoiceGroup(currencyInvoice, t("dashboardGroup.antiquity"))}
                                            >
                                                <Table
                                                    tableComponent={TableComponent}
                                                    messages={tableMessages}
                                                    cellComponent={TableCellComponent}
                                                    columnExtensions={util.returnExtensionGroup(currencyInvoice)}
                                                />
                                                <TableHeaderRow
                                                    cellComponent={tableHeaderCell}
                                                    contentComponent={tableContentComponent}
                                                />
                                                <TableBandHeader
                                                    cellComponent={tableBandCell}
                                                    columnBands={util.returnHeaderInvoiceGroupBand(currencyInvoice, t("dashboardGroup.billstopay"))}
                                                />
                                            </Grid>
                                        </GridUI>
                                        {currencyInvoiceCount <= 1 ?
                                            <GridUI item xs={12} lg className={stylesP.SalesChart}>
                                                <div className={stylesP.ChartContainer}>
                                                    {totalOverdueBills === 0 ?
                                                        <div style={{ textAlign: "center", zIndex: "9999", fontSize: "9pt" }} >{t("miscellaneous.noData")}</div>
                                                        :
                                                        null
                                                    }
                                                    <Doughnut
                                                        data={overdueBill.charts.data}
                                                        options={chartOptionsBills}
                                                    />
                                                </div>
                                            </GridUI>
                                            : null}
                                    </GridUI>
                                </CardContent>
                            </Card>
                        </GridUI>
                    </GridUI>
                </GridUI>
            </GridUI>
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleCloseSnack}
            />
            <Backdrop
                open={showBackdrop}
            />
        </Container>
    )
}

export default DashboardGroup;