/** React imports */
import React from 'react';

/** Icons */
import {ReactComponent as CheckIcon} from "../../Imagenes/check-icon.svg";
import {ReactComponent as CloseIcon} from "../../Imagenes/close-icon.svg";
import {ReactComponent as ErrorIcon} from '../../Imagenes/error-icon.svg';
import {ReactComponent as InfoIcon} from '../../Imagenes/info-icon.svg';
import {ReactComponent as WarningIcon} from "../../Imagenes/warning-icon.svg";

/** Components ----------------------------------------------------------- */
import { makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    alertRoot: {
        borderRadius: 10,
        boxShadow: '10px 10px 5px 0px rgba(74,34,212,0.2)',
        padding: '6px 22px',
    },
    filledSuccess: {
        backgroundColor: '#cce7d3'
    },
    filledInfo: {
        backgroundColor: '#b6e2e9'
    },
    filledWarning: {
        backgroundColor: '#ffe8cb'
    },
    filledError: {
        backgroundColor: '#fbd8e7'
    },
    icon: {
        height: 20,
        fill: '#131f3e',
        paddingRight: 22,
    },
    closeBtn: {
        background: 'none !important',
        padding: '0 10px 0 24px !important'
    },
    closeIcon: {
        height: 20,
        fill: '#131f3e',
    },

}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomizedSnackbars = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar open={props.show} autoHideDuration={6000} onClose={props.onClose}>
                <Alert onClose={props.onClose} severity={props.type}
                    classes={{
                        root: classes.alertRoot,
                        filledSuccess: classes.filledSuccess, 
                        filledInfo: classes.filledInfo,
                        filledWarning: classes.filledWarning,
                        filledError: classes.filledError}}
                    iconMapping={{ 
                        success: <CheckIcon className={classes.icon} />,
                        info: <InfoIcon className={classes.icon} />,
                        warning: <WarningIcon className={classes.icon} />,
                        error: <ErrorIcon className={classes.icon} />}}
                    action={
                        <IconButton className={classes.closeBtn} onClick={props.onClose}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    }
                >
                    <Typography variant="h4" color="textPrimary">  {props.message} </Typography>
                </Alert>
            </Snackbar>
        </div>
    );
}

export default CustomizedSnackbars;