/** React imports */
import React from "react";
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

/** Style sheets imports */
import "./Css/index.css"
import "./Css/Menu.css"
import "./Css/Grid.css"
import "./Css/Window.css"
import "moment/locale/es"

/** Components ----------------------------------------------------------- */
import AdminPanel from './Componentes/internos/AdminPanel'
import Error from './Componentes/Error'
import Loading from './Componentes/Loading'
import Login from "./Componentes/Login"
import NavBar from './Componentes/NavBar'
import DashboardGroup from './Componentes/DashboardGroup'
import DashboardOrg from './Componentes/internos/Dashboard/Dashboard'
import BusinessInvo from './Componentes/internos/Negocio/Ventas'
import BusinessBill from './Componentes/internos/Negocio/Compras'
import ReportsFis from './Componentes/internos/Reportes/Fiscales'
import ReportsLeg from './Componentes/internos/Reportes/Financieros'

const PrivateRoute = ({ component: Component, authed }) => {
    return (
        <Route
            render={(props) => authed !== null
                ? authed.auth === true ?
                    <div>
                        <NavBar />
                        <Component {...props} />
                    </div> : <Redirect to="/" />
                : <Redirect to="/" />}
        />
    )
}

const App = () => {
    const todos = useSelector(state => state.todos);
 
    return (
        <Switch>
            <Route exact path="/">
                <Login />
            </Route>
            <Route path="/loading">
                <Loading />
            </Route>
            <PrivateRoute authed={todos} path="/home" component={DashboardGroup} />
            <PrivateRoute authed={todos} path="/dashboard" component={DashboardOrg} />
            <PrivateRoute authed={todos} path="/business/invoices" component={BusinessInvo} />
            <PrivateRoute authed={todos} path="/business/bills" component={BusinessBill} />
            <PrivateRoute authed={todos} path="/reports/fiscal" component={ReportsFis} />
            <PrivateRoute authed={todos} path="/reports/legal" component={ReportsLeg} />
            <PrivateRoute authed={todos} path="/settings" component={AdminPanel} />
            <Route path="*">
                <Error />
            </Route>
        </Switch>
    )
}

export default App;