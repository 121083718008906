import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

/// controles
import { PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import GridUI from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withStyles, makeStyles } from '@material-ui/core/styles';

/// Componentes
import calls from "../../Js/calls";
import util from "../../Js/util";
import Backdrop from "../../internos/Backdrop";
import SnackBar from "../../internos/SnackBar";
import { ReactComponent as ArrowDownIcon } from '../../../Imagenes/arrow-down-icon.svg';
import InputFile from "../accordion/InputFile";

/// CSS
import "../Css/books.css";
import clsx from "clsx";

var group = [];
var cuentas = [];

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
}))(Tooltip);

const tableStyles = {
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: '#faf8fe',
        },
    },
    tableHeader: {
        border: 'none',
        backgroundColor: '#e4def9',
        fontSize: '15px',
        fontWeight: 600,
        color: '#131f3e',
        '&:first-child': {
            borderRadius: '10px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 10px 0 0',
        }
    },
    tableBody: {
        border: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#131f3e',
    },
};

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#f5f6fa',
    },
})(MuiAccordionSummary);

const tableHeaderCellBase = ({ classes, ...props }) => (
    <TableHeaderRow.Cell
        {...props}
        className={classes.tableHeader}
    />
);

const TableComponentBase = ({ classes, ...props }) => (
    <Table.Table
        {...props}
        className={classes.tableStriped}
    />
);

const TableCellComponentBase = ({ classes, ...props }) => (
    <Table.Cell
        {...props}
        className={classes.tableBody}
    />
);

const tableContentComponent = props => (
    <TableHeaderRow.Content {...props} align={'center'} />
);

const tableHeaderCell = withStyles(tableStyles, { name: 'tableHeaderCell' })(tableHeaderCellBase);
const TableComponent = withStyles(tableStyles, { name: 'TableComponent' })(TableComponentBase);
const TableCellComponent = withStyles(tableStyles, { name: 'TableCellComponent' })(TableCellComponentBase);

const iconStyles = theme => ({
    selectIcon: {
        [theme.breakpoints.up('xl')]: {
            height: '25px',
        },
        [theme.breakpoints.down('lg')]: {
            height: '25px',
        },
    }
});

const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
        return (
            <ArrowDownIcon
                {...rest}
                className={clsx(className, classes.selectIcon)}
            />
        );
    }
);

const useStyles = makeStyles(() => ({
    paper: {
        borderRadius: '10px',
        boxShadow: '10px 10px 5px 0px rgba(74,34,212,0.1)',
        fontSize: '14px'
    },
}));

const ReportsF = () => {
    const todos = useSelector(state => state.todos);
    const [legal, setLegal] = useState(true);
    const [currency, setCurrency] = useState("");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setalertType] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation("global");

    const classes = useStyles();

    const reports = [
        { id: "balanceGenBook", currency: "G", book: t("reports.generalBal") },
        { id: "estResBook", currency: "G", book: t("reports.resultEst") },
        { id: "genledgersumBook", currency: "G", book: t("reports.diaryBook") },
        { id: "genledgerBook", currency: "G", book: t("reports.generalLedger") },
        { id: "certRetFuente", currency: "COP", book: t("reports.certRetSource") },
        { id: "certRetICA", currency: "COP", book: t("reports.certRetInCo") },
        { id: "retICA", currency: "COP", book: t("reports.impRetInCo") },
        { id: "retFuente", currency: "COP", book: t("reports.retSource") },
        { id: "impVenPagar", currency: "COP", book: t("reports.impInvPay") },
        { id: "balPrueba", currency: "COP", book: t("reports.triBal") },
        { id: "balPruebaProCuen", currency: "COP", book: t("reports.balTriPro") }
    ];

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    }

    useEffect(() => {
        returnLegalStatus(todos.orgID);

        returnProvee(todos.orgID).then(result => {
            group = result.data;
        });
        returnCuentas(todos.orgID).then(result => {
            cuentas = result.data;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todos.orgID]);

    //Función utilizada para obtener el proveedor
    const returnProvee = async (item) => {
        return await calls.returnProvee(item);
    }

    //Función utilizada para obtener el impuesto de cada organización
    const returnCuentas = async (item) => {
        return await calls.returnCuentas(item);
    }

    //Función utilizada para obtener el impuesto de cada organización
    const returnLegalStatus = async (item) => {
        return await fetch(`/getOrganisationInfo?id_organisation=${item}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((res) => res.json())
            .then((OrgResponse) => {
                // If razonnSocial is empty means the service did not recover any info
                // Hiding loading
                setTexto(t("miscellaneous.notActMod"));
                setalertType("info");
                setShow(OrgResponse.legal_reports === true ? false : true);
                setLegal(OrgResponse.legal_reports);
                setCurrency(OrgResponse.baseCurrency);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    let reportsF = reports.filter((report) => {
        if (currency !== "COP") {
            return report.currency === "G"
        }
        return report;
    });

    return (
        <Container maxWidth="lg" className="mainPanel">
            <GridUI id={legal === true ? "" : "overlayLF"} container spacing={2} justifyContent="center" alignItems="center">
                <GridUI item xs className="botBreadcrumb">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Typography variant="h1" color="textSecondary">{t("principal.reports")}</Typography>
                        <Typography variant="h1" color="textSecondary">{t("principal.legal")}</Typography>
                    </Breadcrumbs>
                </GridUI>
                <GridUI item xs={12}>
                    <Paper className={classes.paper}>
                        {reportsF.map((report, index) => (
                            <ReportBook
                                key={`report${index}`}
                                reportId={report.id}
                                bookName={report.book}
                                orgIdSelected={todos.orgID}
                                expanded={expanded}
                                handleChange={handleChange}
                                setLoading={setLoading}
                            />
                        ))}
                    </Paper>
                </GridUI>
            </GridUI>
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleClose}
            />
            <Backdrop open={loading} />
        </Container>
    );
}

const ReportBook = (props) => {
    return (
        <Accordion expanded={props.expanded === props.reportId} onChange={props.handleChange(props.reportId)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className="acoordionOptionArrow" />}
                aria-controls="panel1bh-content"
                id={props.reportId}
                className="report-title"
            >
                <Typography variant="h3">{props.bookName}</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionBack">
                <GridUI
                    container
                    direction="row"
                >
                    <GridUI item xs={12}>
                        <Card className="card-container">
                            <CardContent>
                                <BookTable
                                    orgIdSelected={props.orgIdSelected}
                                    bookType={props.reportId}
                                    bookName={props.bookName}
                                    setLoading={props.setLoading}
                                />
                            </CardContent>
                        </Card>
                    </GridUI>
                </GridUI>
            </AccordionDetails>
        </Accordion>
    );
}

const BookTable = (props) => {
    const [rowData, setrowData] = useState([]);
    const [columnDefs1, setcolumnDefs1] = useState([]);
    const [columnDefs2, setcolumnDefs2] = useState([]);
    const [startDate, setstartDate] = useState(new Date());
    const [finishDate, setfinishDate] = useState(new Date());
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setalertType] = useState("");
    const [Value, setValue] = useState("");
    const [ValueCuenta, setValueCuenta] = useState("");
    const [t] = useTranslation("global");
    moment.locale(t("manageAccount.dateFormat"));

    const tableMessages = {
        noData: t("miscellaneous.noData")
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ''} ${t("miscellaneous.pagingOf")} ${count}`,
    };

    const tableColumnExtensions1 = [
        { columnName: 'end_date', align: 'center' },
        { columnName: 'creation_date', align: 'center' },
        { columnName: 'creation_hour', align: 'center' },
        { columnName: 'download_rep', align: 'center' }
    ];

    const tableColumnExtensions2 = [
        { columnName: 'init_date', align: 'center' },
        { columnName: 'end_date', align: 'center' },
        { columnName: 'creation_date', align: 'center' },
        { columnName: 'creation_hour', align: 'center' },
        { columnName: 'download_rep', align: 'center' }
    ];

    //#region Métodos de ciclo de vida
    useEffect(() => {
        const columnDefs1 = [
            { name: "end_date", title: t("reports.endDate") },
            { name: "creation_date", title: t("reports.dateCrea") },
            { name: "creation_hour", title: t("reports.hourCrea") },
            { name: "download_rep", title: t("reports.file") }
        ];

        const columnDefs2 = [
            { name: "init_date", title: t("reports.initDate") },
            { name: "end_date", title: t("reports.endDate") },
            { name: "creation_date", title: t("reports.dateCrea") },
            { name: "creation_hour", title: t("reports.hourCrea") },
            { name: "download_rep", title: t("reports.file") }
        ];
        setcolumnDefs1(columnDefs1);
        setcolumnDefs2(columnDefs2);
        onFillGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const getAndBuildGridDataReportsFinan = (orgIdSelected, type) => {
        return (
            // Getting specific organization data
            calls.getReportsFinan(
                orgIdSelected,
                type,
            ).then(result => {
                return result.data
            })
        );
    }

    const fileColumnRendererFinancial = async (booktype, ID) => {
        props.setLoading(true);
        let resp = false;

        switch (booktype) {
            case "balanceGenBook":
                resp = "/generateBalanceSheetReport?id_report=" + ID;
                break;
            case "estResBook":
                resp = "/generateProfitAndLossReport?id_report=" + ID;
                break;
            case "genledgersumBook":
                resp = "/generateGeneralLedgerSummaryReport?id_report=" + ID;
                break;
            case "genledgerBook":
                resp = "/generateGeneralLedgerReport?id_report=" + ID;
                break;
            case "certRetFuente":
                resp = "/generateRetFuenteReport?id_report=" + ID;
                break;
            case "certRetICA":
                resp = "/generateRetICAReport?id_report=" + ID;
                break;
            case "retFuente":
            case "retICA":
                resp = "/generateImpICAFuenteReport?id_report=" + ID;
                break;
            case "impVenPagar":
                resp = "/generateRetIVAReport?id_report=" + ID;
                break;
            case "balPrueba":
            case "balPruebaProCuen":
                resp = "/generateTestBalanceReport?id_report=" + ID;
                break;
            default:
                break;
        }

        if (resp !== false) {
            let element = document.createElement('a');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.setAttribute('href', resp);
            element.click();
            document.body.removeChild(element);
        }
        else {
            setTexto(t("manageAccount.dnldError"));
            setalertType("warning");
            setShow(true);
        }
        props.setLoading(false);
    }

    const getInitDateEstResult = (orgIdSelected) => {
        return (
            // Getting specific organization data
            calls.getInitDateEstResult(
                orgIdSelected,
            ).then(result => {
                return result
            })
        );
    }

    //#region Métodos de ciclo de vida
    const onFillGrid = async () => {
        props.setLoading(true);
        // Getting data from Xero and building data grid
        switch (props.bookType) {
            case "balanceGenBook":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 1).then(result => {
                    const reportData = result.map((reportData) => {
                        return {
                            ID: reportData._id,
                            end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            creation_hour: reportData.creation_hour,
                            download_rep:
                                <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                    <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                        <GetAppIcon color="primary" />
                                    </IconButton>
                                </LightTooltip>
                        };
                    });
                    // Setting component state
                    setrowData(reportData);
                });
                break;
            case "estResBook":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 2).then(result => {
                    const reportData = result.map((reportData) => {
                        return {
                            ID: reportData._id,
                            init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            creation_hour: reportData.creation_hour,
                            download_rep:
                                <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                    <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                        <GetAppIcon color="primary" />
                                    </IconButton>
                                </LightTooltip>
                        };
                    });
                    // Setting component state
                    setrowData(reportData);
                });
                await getInitDateEstResult(props.orgIdSelected).then(result => {
                    // Setting component state
                    setstartDate(moment(result, "DD/MM/YYYY").toDate());
                });
                break;
            case "genledgersumBook":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 3).then(result => {
                    const reportData = result.map((reportData) => {
                        return {
                            ID: reportData._id,
                            init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            creation_hour: reportData.creation_hour,
                            download_rep:
                                <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                    <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                        <GetAppIcon color="primary" />
                                    </IconButton>
                                </LightTooltip>
                        };
                    });
                    // Setting component state
                    setrowData(reportData);
                });
                break;
            case "genledgerBook":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 4).then(result => {
                    const reportData = result.map((reportData) => {
                        return {
                            ID: reportData._id,
                            init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                            creation_hour: reportData.creation_hour,
                            download_rep:
                                <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                    <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                        <GetAppIcon color="primary" />
                                    </IconButton>
                                </LightTooltip>
                        };
                    });
                    // Setting component state
                    setrowData(reportData);
                });
                break;
            case "certRetFuente":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 5)
                    .then(result => {
                        const reportData = result.map((reportData) => {
                            return {
                                ID: reportData._id,
                                init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_hour: reportData.creation_hour,
                                download_rep:
                                    <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                        <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                            <GetAppIcon color="primary" />
                                        </IconButton>
                                    </LightTooltip>
                            };
                        });
                        // Setting component state
                        setrowData(reportData);
                    });
                break;
            case "certRetICA":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 6)
                    .then(result => {
                        const reportData = result.map((reportData) => {
                            return {
                                ID: reportData._id,
                                init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_hour: reportData.creation_hour,
                                download_rep:
                                    <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                        <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                            <GetAppIcon color="primary" />
                                        </IconButton>
                                    </LightTooltip>
                            };
                        });
                        // Setting component state
                        setrowData(reportData);
                    });
                break;
            case "retICA":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 7)
                    .then(result => {
                        const reportData = result.map((reportData) => {
                            return {
                                ID: reportData._id,
                                init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_hour: reportData.creation_hour,
                                download_rep:
                                    <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                        <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                            <GetAppIcon color="primary" />
                                        </IconButton>
                                    </LightTooltip>
                            };
                        });
                        // Setting component state
                        setrowData(reportData);
                    });
                break;
            case "retFuente":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 8)
                    .then(result => {
                        const reportData = result.map((reportData) => {
                            return {
                                ID: reportData._id,
                                init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_hour: reportData.creation_hour,
                                download_rep:
                                    <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                        <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                            <GetAppIcon color="primary" />
                                        </IconButton>
                                    </LightTooltip>
                            };
                        });
                        // Setting component state
                        setrowData(reportData);
                    });
                break;
            case "impVenPagar":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 9)
                    .then(result => {
                        const reportData = result.map((reportData) => {
                            return {
                                ID: reportData._id,
                                init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_hour: reportData.creation_hour,
                                download_rep:
                                    <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                        <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                            <GetAppIcon color="primary" />
                                        </IconButton>
                                    </LightTooltip>
                            };
                        });
                        // Setting component state
                        setrowData(reportData);
                    });
                break;
            case "balPrueba":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 10)
                    .then(result => {
                        const reportData = result.map((reportData) => {
                            return {
                                ID: reportData._id,
                                init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_hour: reportData.creation_hour,
                                download_rep:
                                    <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                        <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                            <GetAppIcon color="primary" />
                                        </IconButton>
                                    </LightTooltip>
                            };
                        });
                        // Setting component state
                        setrowData(reportData);
                    });
                break;
            case "balPruebaProCuen":
                await getAndBuildGridDataReportsFinan(props.orgIdSelected, 11)
                    .then(result => {
                        const reportData = result.map((reportData) => {
                            return {
                                ID: reportData._id,
                                init_date: moment(reportData.init_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                end_date: moment(reportData.end_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_date: moment(reportData.creation_date, "DD/MM/YYYY").format(t("language.dateFormat")),
                                creation_hour: reportData.creation_hour,
                                download_rep:
                                    <LightTooltip title={t("reports.downReport")} aria-label="descargar">
                                        <IconButton aria-label="descargar" size="small" onClick={() => fileColumnRendererFinancial(props.bookType, reportData._id)}>
                                            <GetAppIcon color="primary" />
                                        </IconButton>
                                    </LightTooltip>
                            };
                        });
                        // Setting component state
                        setrowData(reportData);
                    });
                break;
            default:
                break;
        }
        props.setLoading(false);
    }

    /// Funcion utilizada para obtener el periodo y enviar los parámetros
    /// solicitados por medio de post para generar el guardado en Xero
    const onGetPeriodo = async () => {
        props.setLoading(true);

        let x = "";
        let file = "";
        if (props.bookType !== "balanceGenBook") {
            x = moment(document.getElementById("dtpkDesde" + props.bookType).value, t("language.dateFormat")).format("DD/MM/YYYY");
        }
        if (props.bookType === "genledgerBook" || props.bookType === "genledgersumBook") {
            file = document.getElementById("fileExcel" + props.bookType).files[0];
        }
        let y = moment(document.getElementById("dtpkHasta" + props.bookType).value, t("language.dateFormat")).format("DD/MM/YYYY");
        let z = Value;
        let i = ValueCuenta;

        switch (props.bookType) {
            case "balanceGenBook":
                await util.getAndBuildGridDataReportsBalGen(props.orgIdSelected, y).then(result => {
                    if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                        setTexto(t("reports.errorBalGen"));
                        setalertType("error");
                        setShow(true);
                    }
                    else {
                        onFillGrid();
                        setTexto(t("reports.successBalGen"));
                        setalertType("success");
                        setShow(true);
                    }
                });
                break;
            case "estResBook":
                var startDate = moment(x, 'DD/MM/YYYY');
                var endDate = moment(y, 'DD/MM/YYYY');
                var dateDiff = startDate.diff(endDate, 'days');
                dateDiff = Math.abs(dateDiff);

                if (dateDiff > 365) {
                    setTexto(t("reports.queryYear"));
                    setalertType("warning");
                    setShow(true);
                }
                else {
                    await util.getAndBuildGridDataReportsEstResult(props.orgIdSelected, x, y).then(result => {
                        if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                            setTexto(t("reports.errorEstRes"));
                            setalertType("error");
                            setShow(true);
                        }
                        else {
                            onFillGrid();
                            setTexto(t("reports.successEstRes"));
                            setalertType("success");
                            setShow(true);
                        }
                    });
                }
                break;
            case "genledgersumBook":
                if (file === undefined) {
                    setTexto(t("reports.chooseFile"));
                    setalertType("warning");
                    setShow(true);
                }
                else {
                    let thisExt = file.name.split('.')[1].toLowerCase();
                    if (thisExt !== 'xls' && thisExt !== 'xlsx') {
                        setTexto(t("reports.invalidExt"));
                        setalertType("warning");
                        setShow(true);
                    }
                    else {
                        let data = new FormData();
                        data.append("id_organisation", props.orgIdSelected);
                        data.append("initialDate", x);
                        data.append("endDate", y);
                        data.append("accountTransactions", file);

                        await util.getAndBuildGridDataReportsLedgerSum(data).then(result => {
                            if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                                setTexto(t("reports.errorJournal"));
                                setalertType("error");
                                setShow(true);
                                document.getElementById("fileExcel" + props.bookType).value = "";
                                document.getElementById("fileExcel" + props.bookType + "label").firstChild.innerHTML = t("bank.browse");
                            }
                            else {
                                onFillGrid();
                                setTexto(t("reports.successJournal"));
                                setalertType("success");
                                setShow(true);
                                document.getElementById("fileExcel" + props.bookType).value = "";
                                document.getElementById("fileExcel" + props.bookType + "label").firstChild.innerHTML = t("bank.browse");
                            }
                        });
                    }
                }
                break;
            case "genledgerBook":
                if (file === undefined) {
                    setTexto(t("reports.chooseFile"));
                    setalertType("warning");
                    setShow(true);
                }
                else {
                    let thisExt = file.name.split('.')[1].toLowerCase();
                    if (thisExt !== 'xls' && thisExt !== 'xlsx') {
                        setTexto(t("reports.invalidExt"));
                        setalertType("warning");
                        setShow(true);
                    }
                    else {
                        let data = new FormData();
                        data.append("organizationId", props.orgIdSelected);
                        data.append("startDate", x);
                        data.append("endDate", y);
                        data.append("accountTransactions", file);

                        await util.getAndBuildGridDataReportsLedger(data).then(result => {
                            if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                                setTexto(t("reports.errorLedger"));
                                setalertType("error");
                                setShow(true);
                                document.getElementById("fileExcel" + props.bookType).value = "";
                                document.getElementById("fileExcel" + props.bookType + "label").firstChild.innerHTML = t("bank.browse");
                            }
                            else {
                                onFillGrid();
                                setTexto(t("reports.successLedger"));
                                setalertType("success");
                                setShow(true);
                                document.getElementById("fileExcel" + props.bookType).value = "";
                                document.getElementById("fileExcel" + props.bookType + "label").firstChild.innerHTML = t("bank.browse");
                            }
                        });
                    }
                }
                break;
            case "certRetFuente":
                if (z === "") {
                    setTexto(t("reports.selectPro"));
                    setalertType("warning");
                    setShow(true);
                }
                else {
                    await util.getAndBuildGridDataReportsRetFuente(props.orgIdSelected, x, y, z).then(result => {
                        if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                            setTexto(t("reports.errorCertSource"));
                            setalertType("error");
                            setShow(true);
                        }
                        else {
                            onFillGrid();
                            setTexto(t("reports.successCertSource"));
                            setalertType("success");
                            setShow(true);
                        }
                    });
                }
                break;
            case "certRetICA":
                if (z === "") {
                    setTexto(t("reports.selectPro"));
                    setalertType("warning");
                    setShow(true);
                }
                else {
                    await util.getAndBuildGridDataReportsRetICA(props.orgIdSelected, x, y, z).then(result => {
                        if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                            setTexto(t("reports.errorCertRetInCo"));
                            setalertType("error");
                            setShow(true);
                        }
                        else {
                            onFillGrid();
                            setTexto(t("reports.successCertRetInCo"));
                            setalertType("success");
                            setShow(true);
                        }
                    });
                }
                break;
            case "retICA":
                await util.getAndBuildGridDataReportsImpretICA(props.orgIdSelected, x, y).then(result => {
                    if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                        setTexto(t("reports.errorImpInCom"));
                        setalertType("error");
                        setShow(true);
                    }
                    else {
                        onFillGrid();
                        setTexto(t("reports.successImpInCom"));
                        setalertType("success");
                        setShow(true);
                    }
                });
                break;
            case "retFuente":
                await util.getAndBuildGridDataReportsRetF(props.orgIdSelected, x, y).then(result => {
                    if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                        setTexto(t("reports.errorRetSource"));
                        setalertType("error");
                        setShow(true);
                    }
                    else {
                        onFillGrid();
                        setTexto(t("reports.successRetSource"));
                        setalertType("success");
                        setShow(true);
                    }
                });
                break;
            case "impVenPagar":
                await util.getAndBuildGridDataReportsRetIVA(props.orgIdSelected, x, y)
                    .then(result => {
                        if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                            setTexto(t("reports.errorImpVenPay"));
                            setalertType("error");
                            setShow(true);
                        }
                        else {
                            onFillGrid();
                            setTexto(t("reports.successImpVenPay"));
                            setalertType("success");
                            setShow(true);
                        }
                    });
                break;
            case "balPrueba":
                await util.getAndBuildGridDataReportsBalPrueba(props.orgIdSelected, x, y)
                    .then(result => {
                        if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                            setTexto(t("reports.errorBalTri"));
                            setalertType("error");
                            setShow(true);
                        }
                        else {
                            onFillGrid();
                            setTexto(t("reports.successBalTri"));
                            setalertType("success");
                            setShow(true);
                        }
                    });
                break;
            case "balPruebaProCuen":
                if (z === "" && i === "") {
                    setTexto(t("reports.mustProAcc"));
                    setalertType("warning");
                    setShow(true);
                }
                else {
                    await util.getAndBuildGridDataReportsBalPruebaFiltered(props.orgIdSelected, x, y, z, i)
                        .then(result => {
                            if (result.reportId === "" || result.reportId === undefined || result.reportId === null) {
                                setTexto(t("reports.errorBalTri"));
                                setalertType("error");
                                setShow(true);
                            }
                            else {
                                onFillGrid();
                                setTexto(t("reports.successBalTri"));
                                setalertType("success");
                                setShow(true);
                            }
                        });
                }
                break;
            default:
                break;
        }
        props.setLoading(false);
    };

    /* Funciones Libro */
    const handleChangeStartDate = date => {
        let startDate = moment(date, t("language.dateFormat")).format("DD/MM/YYYY");
        let endDate = moment(document.getElementById("dtpkHasta" + props.bookType).value, t("language.dateFormat")).format("DD/MM/YYYY");
        if (util.compareDates(startDate, endDate) === 1) {
            setTexto(t("reports.initDateMess"));
            setalertType("warning");
            setShow(true);
        } else {
            setstartDate(date);
        }
    };

    const handleChangeFinishDate = date => {
        if (props.bookType !== "balanceGenBook") {
            let endDate = moment(date, t("language.dateFormat")).format("DD/MM/YYYY");
            let StartDate = moment(document.getElementById("dtpkDesde" + props.bookType).value, t("language.dateFormat")).format("DD/MM/YYYY");

            if (util.compareDates(endDate, StartDate) === -1) {
                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setfinishDate(date);
            }
        }
        else {
            setfinishDate(date);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    }

    const handleClick = (event) => {
        setValue(event.target.value);
    };

    const handleClickCuentas = (event) => {
        setValueCuenta(event.target.value);
    }

    return (
        <>
            <GridUI
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <GridUI item xs>
                    <GridUI container justifyContent="flex-start" spacing={3}
                        alignItems="center">
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={t("manageAccount.dateFormat")}>
                            {props.bookType !== "balanceGenBook" ? (
                                <GridUI item xs={12} md={2} lg={2}>
                                    <DatePicker
                                        autoOk
                                        id={`dtpkDesde${props.bookType}`}
                                        fullWidth
                                        value={startDate}
                                        onChange={handleChangeStartDate}
                                        format={t("language.dateFormat")}
                                        variant="inline"
                                        label={t("reports.initDate")}
                                    />
                                </GridUI>
                            ) : <GridUI item></GridUI>}
                            <GridUI item xs={12} md={2} lg={2}>
                                <DatePicker
                                    autoOk
                                    id={`dtpkHasta${props.bookType}`}
                                    fullWidth
                                    value={finishDate}
                                    onChange={handleChangeFinishDate}
                                    format={t("language.dateFormat")}
                                    variant="inline"
                                    label={props.bookType !== "balanceGenBook" ? t("reports.endDate") : t("miscellaneous.date")}
                                />
                            </GridUI>
                            {props.bookType === "genledgerBook" || props.bookType === "genledgersumBook" ? (
                                <InputFile id={`fileExcel${props.bookType}`} tooltip={t("reports.validFormat")} place="bottom" />
                            ) : <GridUI item></GridUI>}
                            {props.bookType === "certRetFuente" || props.bookType === "certRetICA" || props.bookType === "balPruebaProCuen" ? (
                                <GridUI item xs={12} md={3} lg={3}>
                                    <InputLabel shrink id="select-label">
                                        {t("miscellaneous.supplier")}
                                    </InputLabel>
                                    <Select
                                        id={`ContactID${props.bookType}`}
                                        labelId="select-label"
                                        fullWidth
                                        value={Value}
                                        onChange={handleClick}
                                        placeholder={t("miscellaneous.selectOption")}
                                        displayEmpty
                                        IconComponent={CustomExpandMore}
                                        className="ddlAdminPanel"
                                        MenuProps={{
                                            classes: {
                                                paper: "selectPaperAdmin",
                                            }
                                        }}
                                    >
                                        <MenuItem value="" className="ddlAdminPanel">
                                            {t("miscellaneous.all")}
                                        </MenuItem>
                                        {group.map((contact) =>
                                            <MenuItem value={contact.contactID} className="ddlAdminPanel">{contact.contactName}</MenuItem>
                                        )}
                                    </Select>
                                </GridUI>
                            ) : <GridUI item></GridUI>}
                            {props.bookType === "balPruebaProCuen" ? (
                                <GridUI item xs={12} md={3} lg={3}>
                                    <InputLabel shrink id="select-label">
                                        {t("miscellaneous.account")}
                                    </InputLabel>
                                    <Select
                                        id={`AccountID${props.bookType}`}
                                        labelId="select-label"
                                        fullWidth
                                        value={ValueCuenta}
                                        onChange={handleClickCuentas}
                                        placeholder={t("miscellaneous.selectOption")}
                                        displayEmpty
                                        IconComponent={CustomExpandMore}
                                        className="ddlAdminPanel"
                                        MenuProps={{
                                            classes: {
                                                paper: "selectPaperAdmin",
                                            }
                                        }}
                                    >
                                        <MenuItem value="" className="ddlAdminPanel">
                                            {t("miscellaneous.all")}
                                        </MenuItem>
                                        {cuentas.map((account) =>
                                            <MenuItem value={account.accountID} className="ddlAdminPanel">{account.accountName}</MenuItem>
                                        )}
                                    </Select>
                                </GridUI>
                            ) : <GridUI item></GridUI>}
                        </MuiPickersUtilsProvider>
                    </GridUI>
                </GridUI>
                <GridUI item>
                    <Button
                        variant="contained"
                        id="btnGenerar"
                        color="primary"
                        className="ButtonReports"
                        onClick={() => {
                            onGetPeriodo()
                        }}
                        disableElevation
                    >
                        {t("reports.generate")}
                    </Button>
                </GridUI>
            </GridUI>
            {/** Tabla de datos de libros generados por periodo */}
            <div className="aggridReport">
                <Grid
                    rows={rowData}
                    columns={props.bookType !== "balanceGenBook" ? columnDefs2 : columnDefs1}
                >
                    <PagingState
                        defaultCurrentPage={0}
                        pageSize={10}
                    />
                    <IntegratedPaging />
                    <Table
                        tableComponent={TableComponent}
                        messages={tableMessages}
                        cellComponent={TableCellComponent}
                        columnExtensions={props.bookType !== "balanceGenBook" ? tableColumnExtensions2 : tableColumnExtensions1}
                    />
                    <TableHeaderRow
                        cellComponent={tableHeaderCell}
                        contentComponent={tableContentComponent}
                    />
                    <PagingPanel messages={pagingMessages} />
                </Grid>
            </div>
            <SnackBar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleClose}
            />
        </>
    );
}

export default ReportsF;