import React from "react";
import "../Css/BanksConvert/inputfile.css";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(19, 31, 62, 0.80)',
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: 'normal'
    },
}))(Tooltip);

///  Componente InputFile para cambiar el estilo del botón
/// default del input de tipo file que da el explorador web
const InputFile = (props) => {
    const [t] = useTranslation("global");

    const setFileInfo = (event) => {
        var fileName = "";
        var element = event.target.nextElementSibling.children[0].children[0];
        var label = element,
            labelVal = label.innerHTML;

        if (event.target.files && event.target.files.length > 1)
            fileName = (
                event.target.getAttribute("data-multiple-caption") || ""
            ).replace("{count}", event.target.files.length);
        else fileName = event.target.value.split("\\").pop();

        if (fileName) label.innerHTML = fileName;
        else label.innerHTML = labelVal;

        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <div className="inputFileContainer">
            <input
                className="inputFile"
                id={props.id}
                type="file"
                onChange={(event) => setFileInfo(event)}
            />
            <label htmlFor={props.id}>
                <LightTooltip title={props.tooltip} placement={props.place}>
                    <Button id={props.id + "label"} variant="outlined" color="primary" component="span" className="inputFileLabel">
                        {t("bank.browse")}
                    </Button>
                </LightTooltip>
            </label>
        </div>
    );
}

export default InputFile;
